import React from 'react';
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import TopBar from '../../components/TopBar/TopBar';
import './WithTopBarAndMenuHoc.css';

const WithTopBarAndMenuHoc = Component => ({ ...props }) => {

  return (
    <div className="screenContainer">
      <TopBar />
      <div className="screenWrapperContainer">
        <SideNavigation />
        <Component {...props} />
      </div>
    </div>
  )
};

export default WithTopBarAndMenuHoc;
