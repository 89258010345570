import React, {useEffect, useState} from 'react';
import '../Reports.css';
import {useTranslation} from "react-i18next";
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { generateConstructionWorkLogReportAction } from '../../../redux/actions/reportActions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import ConstructionStatus from '../../../constants/ConstructionStatus';
import { fetchConstructionsAction } from '../../../redux/actions/constructionActions';

function ConstructionWorkLogReport() {
  const {t} = useTranslation();
  const constructions = useSelector((state) => state.construction.constructions);
  const constructionOptions = (constructions && constructions.length > 0) ? 
    constructions.map(c => ({
      value: c.id, 
      label: c.constructionName
    })) : 
    [];
  const [selectedConstruction, setSelectedConstruction] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    fetchConstructionsAction(ConstructionStatus.ACTIVE);
  }, []);

  const onSubmit = () => {
    generateConstructionWorkLogReportAction({
      constructionId: selectedConstruction.value,
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf()
    });
  }

  const renderTitle = () => {
    return (
      <div className="reportTitleContainer">
        <div className="reportTitleText">
          {t("reports.constructionWorkLogReportTitle")}
        </div>    
      </div>
    )
  }

  return (
    <div className="reportContainer">
      {renderTitle()}
      <div className="reportDescription">
        {t("reports.constructionWorkLogReportDescription")}
      </div>
      <div className="reportForm">
        <div className="reportFormRow">
          <CustomDropdown value={selectedConstruction} 
                          onChange={setSelectedConstruction} 
                          options={constructionOptions} 
                          label={t("reports.construction")} 
                          className="reportInput reportDropdown"/>
          <CustomDatePicker value={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            label={t("reports.startDate")} 
                            className='reportInput'/>
          <CustomDatePicker value={endDate} 
                            onChange={(date) => setEndDate(date)} 
                            label={t("reports.endDate")} 
                            className='reportInput'/>
        </div>
        <CustomButton label={t("common.generate")} onClick={onSubmit} isDisabled={!selectedConstruction || !startDate || !endDate}/>
      </div>
    </div>
  );
}

export default ConstructionWorkLogReport;
