import { createSlice } from '@reduxjs/toolkit'

export const userDayOffSlice = createSlice({
  name: 'userDayOff',
  initialState: {
    isLoading: false,
    userDaysOff: []
  },
  reducers: {
    fetchUserDaysOffStart: (state) => {
        state.isLoading = true
    },
    fetchUserDaysOffSuccess: (state, action) => {
        state.userDaysOff = action.payload;
        state.isLoading = false;
    },
    fetchUserDaysOffFailed: (state) => {
      state.isLoading = false
    }
  },
})

export const { 
  fetchUserDaysOffStart, 
  fetchUserDaysOffSuccess, 
  fetchUserDaysOffFailed
} = userDayOffSlice.actions

export default userDayOffSlice.reducer