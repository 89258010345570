import { createSlice } from '@reduxjs/toolkit'

export const fuelTankSlice = createSlice({
  name: 'fuelTank',
  initialState: {
    isLoading: false,
    fuelTanks: [],
    contextFuelTank: null
  },
  reducers: {
    fetchFuelTanksStart: (state) => {
        state.isLoading = true
    },
    fetchFuelTanksSuccess: (state, action) => {
        state.fuelTanks = action.payload;
        state.isLoading = false;
    },
    fetchContextFuelTankSuccess: (state, action) => {
      state.contextFuelTank = action.payload;
      state.isLoading = false;
    },
    fetchFuelTanksFailed: (state) => {
        state.isLoading = false
    },
  },
})

export const { 
  fetchFuelTanksStart, 
  fetchFuelTanksSuccess, 
  fetchContextFuelTankSuccess,
  fetchFuelTanksFailed
} = fuelTankSlice.actions

export default fuelTankSlice.reducer