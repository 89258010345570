// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customButton {
  min-width: 100px;
  text-align: center;
  line-height: 30px;
  justify-content: center;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  border: 1px solid #e51b22;
  text-transform : uppercase;
  font-size: 14px;
  color: #e51b22;
}

.disabled {
  opacity: 0.5;
  cursor: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomButton/CustomButton.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,0BAA0B;EAC1B,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".customButton {\n  min-width: 100px;\n  text-align: center;\n  line-height: 30px;\n  justify-content: center;\n  cursor: pointer;\n  padding-left: 30px;\n  padding-right: 30px;\n  padding-top: 2px;\n  padding-bottom: 2px;\n  border-radius: 5px;\n  border: 1px solid #e51b22;\n  text-transform : uppercase;\n  font-size: 14px;\n  color: #e51b22;\n}\n\n.disabled {\n  opacity: 0.5;\n  cursor: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
