// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customDatePickerContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

react-datepicker-popper {
  z-index: 1004;
}

.customDatePickerLabel {
  color: #e51b22;
  margin-bottom: 5px;
  font-weight: 700;
}

.customDatePicker {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #f3f3f3;
  border: none;
  border-bottom: 1px solid #000000;
  padding: 12px;
  font-size: 16px;
  color: hsl(0, 0%, 20%);
  width: calc(100% - 20px);
}

.customDatePicker:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomDatePicker/CustomDatePicker.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,yBAAyB;EACzB,YAAY;EACZ,gCAAgC;EAChC,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".customDatePickerContainer {\n  display: flex;\n  flex-direction: column;\n  margin-left: 20px;\n}\n\nreact-datepicker-popper {\n  z-index: 1004;\n}\n\n.customDatePickerLabel {\n  color: #e51b22;\n  margin-bottom: 5px;\n  font-weight: 700;\n}\n\n.customDatePicker {\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n  background-color: #f3f3f3;\n  border: none;\n  border-bottom: 1px solid #000000;\n  padding: 12px;\n  font-size: 16px;\n  color: hsl(0, 0%, 20%);\n  width: calc(100% - 20px);\n}\n\n.customDatePicker:focus {\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
