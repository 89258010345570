import store from '../store';
import { getPagedUserWorkLogs, deleteWorkLog, editWorkLog, addWorkLog } from '../../services/workLogService';
import { workLogSlice } from '../features/workLogSlice';
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import ErrorCode from '../../constants/ErrorCode';
import i18next from 'i18next';

export async function fetchUserWorkLogsAction(userId, page, rowsPerPage, sortBy, sortDirection) {
    store.dispatch(workLogSlice.actions.fetchWorkLogsStart());
    return getPagedUserWorkLogs(userId, page, rowsPerPage, sortBy, sortDirection).then((workLogsFetchResponse) => {
        store.dispatch(workLogSlice.actions.fetchUserWorkLogsSuccess(workLogsFetchResponse.data));
    }).catch((error) => {
        store.dispatch(workLogSlice.actions.fetchWorkLogsFailed());
    });
}

export async function deleteWorkLogAction(workLogId, userId) {
    store.dispatch(workLogSlice.actions.fetchWorkLogsStart());
    return deleteWorkLog(workLogId).then(() => {
        return getPagedUserWorkLogs(userId);
    }).then((workLogsFetchResponse) => {
        store.dispatch(workLogSlice.actions.fetchUserWorkLogsSuccess(workLogsFetchResponse.data));
    }).catch((error) => {
        store.dispatch(workLogSlice.actions.fetchWorkLogsFailed());
    });
}

export async function addWorkLogAction(body, userId) {
    store.dispatch(workLogSlice.actions.fetchWorkLogsStart());
    return addWorkLog(body).then(() => {
        return getPagedUserWorkLogs(userId);
    }).then((workLogsFetchResponse) => {
        store.dispatch(workLogSlice.actions.fetchUserWorkLogsSuccess(workLogsFetchResponse.data));
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.START_DATE_AFTER_END_DATE) {
            showNotification(NotificationType.WARNING, i18next.t('actions.startDateAfterEndDate'));
        } else if (errorData && errorData === ErrorCode.WORK_LOG_CREATE_DATE_CONFLICT) {
            showNotification(NotificationType.WARNING, i18next.t('actions.workLogDateConflict'));
        } else if (errorData && errorData === ErrorCode.EXISTING_OPEN_WORK_LOGS) {
            showNotification(NotificationType.WARNING, i18next.t('actions.existingOpenWorkLogs'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.workLogAddError'));
        }
        store.dispatch(workLogSlice.actions.fetchWorkLogsFailed());
    });
}

export async function editWorkLogAction(body, userId) {
    store.dispatch(workLogSlice.actions.fetchWorkLogsStart());
    return editWorkLog(body).then(() => {
        return getPagedUserWorkLogs(userId);
    }).then((workLogsFetchResponse) => {
        store.dispatch(workLogSlice.actions.fetchUserWorkLogsSuccess(workLogsFetchResponse.data));
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.START_DATE_AFTER_END_DATE) {
            showNotification(NotificationType.WARNING, i18next.t('actions.startDateAfterEndDate'));
        } else if (errorData && errorData === ErrorCode.WORK_LOG_CREATE_DATE_CONFLICT) {
            showNotification(NotificationType.WARNING, i18next.t('actions.workLogDateConflict'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.workLogEditError'));
        }
        store.dispatch(workLogSlice.actions.fetchWorkLogsFailed());
    });
}