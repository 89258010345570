import React from 'react';
import {useTranslation} from 'react-i18next';
import UserRole from '../../constants/UserRole';
import './CustomUserRole.css';

function CustomUserRole({role}) {
  const {t} = useTranslation();

  const getColor = () => {
    switch (role) {
      case UserRole.SUPER_ADMIN:
        return '#544266';
      case UserRole.TENANT_ADMIN:
        return '#fd413b';
      case UserRole.WAREHOUSE:
        return '#1078fc';
      case UserRole.MANAGER:
        return '#f4a14e';
      case UserRole.TENANT_DIRECTOR:
        return '#e2d50b';
      case UserRole.TERMINAL:
        return '#b3b3b3';
      case UserRole.USER:
      default:
        return '#68943b';
    }
  };

  return (
    <div className="customUserRoleContainer" style={{backgroundColor: getColor()}}>
      <div className="customUserRoleText" >{t('common.userRole.' + role)}</div>
    </div>
  );
}

export default CustomUserRole;
