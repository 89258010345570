import axiosService from './axiosService';

export const getUserDaysOff = (userId, startDate, endDate) => {
    let params = "";
    if (startDate)
        params = params + "&startDate=" + startDate;
    if (endDate)
        params = params + "&endDate=" + endDate
    return axiosService.get("/userDayOff/user?userId=" + userId + params);
}

export const addUserDaysOff = (body) => {
    return axiosService.post("/userDayOff", body);
}

export const deleteUserDayOff = (dayOffId) => {
    return axiosService.delete("/userDayOff/" + dayOffId);
}

