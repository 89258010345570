import React, { useEffect, useState } from 'react';
import './TenantManagement.css';
import {useTranslation} from "react-i18next";
import { fetchTenantsAction } from '../../redux/actions/tenantActions';
import { useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../utils/tableUtils';
import moment from 'moment';
import CustomButton from '../../components/CustomButton/CustomButton';
import TenantStatus from '../../constants/TenantStatus';
import { useNavigate } from "react-router-dom";

function TenantManagement() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(TenantStatus.ACTIVE);
  const tenants = useSelector((state) => state.tenant.tenants);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (selectedStatus)
      fetchTenantsAction(selectedStatus);
  }, [selectedStatus]);

  const onRowClick = (event, rowData) => {
    navigate("/tenants/edit/" + rowData.id);
  }

  const mappedTenants = !tenants ? [] : tenants.map(tenant => ({
    ...tenant,
    status: t("common.status." + tenant.status),
    defaultLanguage: t("common.language." + tenant.defaultLanguage),
    creationDate: moment(tenant.creationDate).format("DD/MM/YYYY HH:mm")
  }))

  const renderTitle = () => {
    return (
      <>
        <div className="screenTitleContainer spaceBetween">
          <div className="screenTitleText">
            {t("sideMenu.tenants")}
          </div>
          <CustomButton label={t("common.add")} onClick={() => navigate("/tenants/add")}/>
        </div>
        <div className="statusButtons">
          {Object.keys(TenantStatus).map(status => (
            <div  key={"tenant-management-button-" + status}
                  className={"statusButton" + (status === selectedStatus ? " statusButtonActive" : "")}
                  onClick={() => setSelectedStatus(status)}
            >
              {t("common.status." + status)}
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="innerScreenContainer">
      <MaterialTable
        icons={tableIcons}
        columns={[
          { 
            title: t("tableCommons.tenantNameLabel"), 
            field: 'tenantName',
            customSort: (a, b) => a.tenantName.localeCompare(b.tenantName, user.language)
          },
          { title: t("tableCommons.defaultLanguageLabel"), field: 'defaultLanguage' },
          { title: t("tableCommons.creationDateLabel"), field: 'creationDate' },
        ]}
        data={mappedTenants}
        title={renderTitle()}
        onRowClick={onRowClick}
        options={{
          paging:true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100],
        }}
        localization={{
          body: {
              emptyDataSourceMessage: t("tableCommons.noRecords")
          },
          toolbar: {
            searchPlaceholder: t("common.search")
          },
          pagination: {
            labelRowsSelect: t("tableCommons.rows")
          }
        }}
      />
    </div>
  );
}

export default TenantManagement;
