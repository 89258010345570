import store from '../store';
import i18next from "i18next";
import { authSlice } from '../features/authSlice';
import { loginUser, getAuthorizedUser, loginSuperAdmin } from '../../services/authService';
import ErrorCode from '../../constants/ErrorCode';
import LocalStorageKey from '../../constants/LocalStorageKey';
import jwt_decode from "jwt-decode";
import UserRole from '../../constants/UserRole';
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import { editUser } from '../../services/userService';
import { userSlice } from '../features/userSlice';

export async function loginUserAction(tenantName, username, password) {
    store.dispatch(authSlice.actions.loginStart());
    return loginUser(tenantName, username, password).then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.data.accessToken;
        const decodedToken = jwt_decode(accessToken)
        if (decodedToken && decodedToken.role !== UserRole.TENANT_ADMIN && decodedToken.role !== UserRole.TENANT_DIRECTOR)
            return Promise.reject({response: {data: ErrorCode.UNAUTHORIZED}});
        
        window.location.replace('/users');
        localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
        return getAuthorizedUser();
    }).then(authorizedUser => {
        i18next.changeLanguage(authorizedUser.data.language.toLowerCase());
        store.dispatch(authSlice.actions.loginSuccess(authorizedUser.data));
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        store.dispatch(authSlice.actions.loginFailed());
        if (errorData && errorData === ErrorCode.TENANT_NAME_NOT_RECOGNIZED) {
            showNotification(NotificationType.WARNING, i18next.t('error.tenantName'));
        } else if (errorData && errorData === ErrorCode.USER_DISABLED) {
            showNotification(NotificationType.WARNING, i18next.t('error.userOrTenantDisabled'));
        } else if (errorData && (errorData === ErrorCode.UNAUTHORIZED || errorData === ErrorCode.USER_DOES_NOT_EXIST)) {
            showNotification(NotificationType.WARNING, i18next.t('error.loginPasswordError'));
        }
    });
}

export async function loginSuperAdminAction(username, password) {
    store.dispatch(authSlice.actions.loginStart());
    return loginSuperAdmin(username, password).then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.data.accessToken;
        const decodedToken = jwt_decode(accessToken)
        if (decodedToken && decodedToken.role !== UserRole.SUPER_ADMIN)
            return Promise.reject(ErrorCode.UNAUTHORIZED);

        window.location.replace('/tenants');
        localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
        return getAuthorizedUser();
    }).then(authorizedUser => {
        i18next.changeLanguage(authorizedUser.data.language.toLowerCase());
        store.dispatch(authSlice.actions.loginSuccess(authorizedUser.data));
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        store.dispatch(authSlice.actions.loginFailed());
        if (errorData && (errorData === ErrorCode.UNAUTHORIZED || errorData === ErrorCode.USER_DOES_NOT_EXIST)) {
            showNotification(NotificationType.WARNING, i18next.t('error.loginPasswordError'));
        }
    });
}

export async function refreshAuthorizationAction() {
    store.dispatch(authSlice.actions.loginStart());
    return getAuthorizedUser().then(authorizedUser => {
        i18next.changeLanguage(authorizedUser.data.language.toLowerCase());
        store.dispatch(authSlice.actions.loginSuccess(authorizedUser.data));
    }).catch(() => {
        store.dispatch(authSlice.actions.loginFailed());
    });
}

export async function changeLanguageAction(language) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
    const decodedToken = jwt_decode(accessToken);
    return editUser({userId: decodedToken.id, language}).then((updatedUser) => {
        i18next.changeLanguage(updatedUser.data.language.toLowerCase());
        store.dispatch(authSlice.actions.loginSuccess(updatedUser.data));
        showNotification(NotificationType.SUCCESS, i18next.t('actions.changeLanguageSuccess'));
        store.dispatch(userSlice.actions.fetchUsersVoidSuccess());
    }).catch((error) => {
        store.dispatch(userSlice.actions.fetchUsersFailed());
        showNotification(NotificationType.DANGER, i18next.t('actions.editUserError'));
    });
}

export async function logoutAction() {
    store.dispatch(authSlice.actions.logout());
    localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
    window.location.replace('/');
    setTimeout(() => {
        store.dispatch(authSlice.actions.logoutSuccess());
    }, 1000);

}