// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customPopupOverlay {
  background-color: rgba(255,255,255,0.8);
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.customPopupContainer {
  background-color: #ffffff;
  border-radius: 5px;
  min-width: 50%;
  z-index: 9999;
  padding: 50px;
  border: 1px solid #c0c0c0;
  box-shadow: 0px 0px 6px 0px rgba(163, 164, 167, 1);
  max-height: 80vh;
  overflow: auto
}

.customPopupTitle {
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
  color: #e51b22;
}

.customPopupButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomPopup/CustomPopup.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,eAAe;EACf,aAAa;EACb,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,aAAa;EACb,yBAAyB;EAGzB,kDAAkD;EAClD,gBAAgB;EAChB;AACF;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B","sourcesContent":[".customPopupOverlay {\n  background-color: rgba(255,255,255,0.8);\n  position: fixed;\n  display: flex;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  z-index: 9999;\n}\n\n.customPopupContainer {\n  background-color: #ffffff;\n  border-radius: 5px;\n  min-width: 50%;\n  z-index: 9999;\n  padding: 50px;\n  border: 1px solid #c0c0c0;\n  -webkit-box-shadow: 0px 0px 6px 0px rgba(163, 164, 167, 1);\n  -moz-box-shadow: 0px 0px 6px 0px rgba(163, 164, 167, 1);\n  box-shadow: 0px 0px 6px 0px rgba(163, 164, 167, 1);\n  max-height: 80vh;\n  overflow: auto\n}\n\n.customPopupTitle {\n  font-size: 24px;\n  text-align: center;\n  margin-bottom: 40px;\n  color: #e51b22;\n}\n\n.customPopupButtons {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
