import React, { useState } from 'react';
import './TenantAdd.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Language from '../../../constants/Language';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import { addTenantAction } from '../../../redux/actions/tenantActions';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from "react-router-dom";

function TenantAdd() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const languageOptions = Object.keys(Language).map(key => ({value: key, label: t("common.language." + key)}));
  const [tenantName, setTenantName] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState(languageOptions[0]);

  const onSubmit = () => {
    addTenantAction({
      tenantName, 
      defaultLanguage: defaultLanguage.value
    }, () => navigate("/tenants"));
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer">
        <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
        <div className="nestedScreenTitle">
          {t("tenantManagement.addTitle")}
        </div>
      </div>
    )
  }

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      <TextInput  value={tenantName} 
                  onChange={setTenantName} 
                  label={t("tableCommons.tenantNameLabel")} 
                  className="halfWidth"
                  maxLength={255} />
      <CustomDropdown value={defaultLanguage} 
                      onChange={setDefaultLanguage} 
                      options={languageOptions} 
                      label={t("tableCommons.defaultLanguageLabel")} 
                      className="halfWidth"/>
      <div className="formButtons halfWidth" >
        <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!tenantName}/>
      </div>
    </div>
  );
}

export default TenantAdd;
