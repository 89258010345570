import React from 'react';
import './CustomReadOnly.css';

function CustomReadOnly({value, label, style, className}) {

  return (
    <div className={"readOnlyContainer" + (className ? (" " + className) : "")} style={style ? style : {}}>
      {label && 
        <div className={"readOnlyLabel"}>
          {label}
        </div>
      }
      <div className={"readOnly"}>
        {value}
      </div>
    </div>
  );
}

export default CustomReadOnly;
