import { createSlice } from '@reduxjs/toolkit'

export const fuelLogSlice = createSlice({
  name: 'fuelLog',
  initialState: {
    isLoading: false,
    fuelLogs: []
  },
  reducers: {
    fetchFuelLogsStart: (state) => {
        state.isLoading = true
    },
    fetchFuelLogsSuccess: (state, action) => {
        state.fuelLogs = action.payload;
        state.isLoading = false;
    },
    fetchFuelLogsFailed: (state) => {
        state.isLoading = false
    },
  },
})

export const { 
  fetchFuelLogsStart, 
  fetchFuelLogsSuccess, 
  fetchFuelLogsFailed
} = fuelLogSlice.actions

export default fuelLogSlice.reducer