import React, { useEffect, useState } from 'react';
import './WorkLogList.css';
import {useTranslation} from "react-i18next";
import { tableIcons } from '../../utils/tableUtils';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import { useSelector } from 'react-redux';
import { fetchUserWorkLogsAction, deleteWorkLogAction } from '../../redux/actions/workLogActions';
import { MdDeleteOutline, MdEditNote } from "react-icons/md";
import CustomPopup from '../../components/CustomPopup/CustomPopup';
import WorkLogEditPopup from './WorkLogEditPopup';

function WorkLogList({contextUser}) {
  const {t} = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const workLogs = useSelector((state) => state.workLog.userWorkLogs);
  const [workLogIdToEdit, setWorkLogIdToEdit] = useState(null);
  const [workLogIdToDelete, setWorkLogIdToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  useEffect(() => {
    if (contextUser)
      fetchUserWorkLogsAction(contextUser.id, page, rowsPerPage, sortBy, sortDirection);
  }, [contextUser, page, rowsPerPage, sortBy, sortDirection]);

  const onWorkLogDelete = (workLogId) => {
    deleteWorkLogAction(workLogId, contextUser.id).then(() => {
      setWorkLogIdToDelete(null);
    });
  }

  const onOrderChange = (fieldIndex, direction) => {
    switch(fieldIndex) {
      case 0:
        setSortBy("constructionName");
        break;
      case 1:
        setSortBy("startDate");
        break;
      case 2:
        setSortBy("endDate");
        break;
      case 3:
        setSortBy("isAutoClosed");
        break;
      case 4:
        setSortBy("updatedDate");
        break;
      case -1:
      default:
        setSortBy(null);
        break;
    }
    setSortDirection(direction);
  }

  const onRowsPerPageChange = (param) => {
    setRowsPerPage(param);
  }

  const onPageChange = (param) => {
    setPage(param);
  }

  const mappedWorkLogs = (!workLogs || !workLogs.content) ? [] : workLogs.content.map(wl => ({
    ...wl,
    startDate: moment(wl.startDate).format("DD/MM/YYYY HH:mm"),
    endDate: wl.endDate ? moment(wl.endDate).format("DD/MM/YYYY HH:mm") : '-',
    rawStartDate: wl.startDate,
    rawEndDate: wl.endDate,
    updatedDate: wl.updatedDate ? moment(wl.updatedDate).format("DD/MM/YYYY HH:mm") : '-',
    rawUpdatedDate: wl.updatedDate,
    isAutoClosed: wl.isAutoClosed ? t("common.yes") : t("common.no"),
    managerName: wl.manager ? wl.manager.username : "-",
    edit: <MdEditNote className="tableIcon" onClick={() => setWorkLogIdToEdit(wl.id)} color={'#696969'}/>,
    delete: <MdDeleteOutline className="tableIcon" onClick={() => setWorkLogIdToDelete(wl.id)} color={'#e51b22'}/>
  }))

  const renderEditWorkLogPopup = () => {
    if (!workLogIdToEdit || !workLogs || !workLogs.content || workLogs.content.length === 0)
      return null;

    const workLog = workLogs.content.find(wl => wl.id === workLogIdToEdit);
    if (!workLog)
      return null;

    return (
      <WorkLogEditPopup contextUser={contextUser} 
                        workLog={workLog} 
                        onClose={() => setWorkLogIdToEdit(null)}/>
    )
  }

  const renderDeleteWorkLogPopup = () => {
    if (!workLogIdToDelete)
      return null;

    return (
      <CustomPopup  label={t("workLogManagement.deleteWorkLogTitle")} 
                    onAccept={() => onWorkLogDelete(workLogIdToDelete)} 
                    close={() => setWorkLogIdToDelete(null)}/>
    )
  }

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { 
            title: t("tableCommons.constructionNameLabel"), 
            field: 'construction.constructionName',
            customSort: (a, b) => a.construction.constructionName.localeCompare(b.construction.constructionName, user.language) 
          },
          { 
            title: t("tableCommons.startDateLabel"), 
            field: 'startDate',
            customSort: (a, b) => a.rawStartDate > b.rawStartDate
          },
          { 
            title: t("tableCommons.endDateLabel"),
            field: 'endDate',
            customSort: (a, b) => a.rawEndDate > b.rawEndDate
          },
          { title: t("tableCommons.isAutoClosed"), field: 'isAutoClosed' },
          { 
            title: t("tableCommons.lastUpdated"), 
            field: 'updatedDate',
            customSort: (a, b) => a.rawUpdatedDate > b.rawUpdatedDate
          },
          { 
            title: t("tableCommons.managerName"), 
            field: 'managerName'
          },
          { title: t("tableCommons.editLabel"), field: 'edit', width: "100px" },
          { title: t("tableCommons.deleteLabel"), field: 'delete', width: "100px" }
        ]}
        data={mappedWorkLogs}
        title={null}
        options={{
          paging: true,
          search: false,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100],
        }}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        onOrderChange={onOrderChange}
        page={page}
        totalCount={(workLogs && workLogs.totalElements) ? workLogs.totalElements : 0}
        localization={{
          body: {
              emptyDataSourceMessage: t("tableCommons.noRecords")
          },
          toolbar: {
            searchPlaceholder: t("common.search")
          },
          pagination: {
            labelRowsSelect: t("tableCommons.rows")
          }
        }}
      />
      {renderDeleteWorkLogPopup()}
      {renderEditWorkLogPopup()}
    </>
  );
}

export default WorkLogList;
