import store from '../store';
import { 
    generateUserWorkLogReport, 
    generateConstructionWorkLogReport, 
    generateFuelLogReport,
    generateUserWorkMonthReport
} from '../../services/reportService';
import { reportSlice } from '../features/reportSlice';
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import ErrorCode from '../../constants/ErrorCode';
import i18next from "i18next";

export async function generateUserWorkLogReportAction(data) {
    store.dispatch(reportSlice.actions.fetchReportsStart());
    return generateUserWorkLogReport(data).then((response) => {
        store.dispatch(reportSlice.actions.fetchReportsVoidSuccess());
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'user-work-log-report-' + data.userId + '.csv');
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.START_DATE_AFTER_END_DATE) {
            showNotification(NotificationType.WARNING, i18next.t('actions.startDateAfterEndDate'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.reportError'));
        }
        store.dispatch(reportSlice.actions.fetchReportsFailed());
    });
}

export async function generateConstructionWorkLogReportAction(data) {
    store.dispatch(reportSlice.actions.fetchReportsStart());
    return generateConstructionWorkLogReport(data).then((response) => {
        store.dispatch(reportSlice.actions.fetchReportsVoidSuccess());
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'construction-work-log-report-' + data.constructionId + '.csv');
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.START_DATE_AFTER_END_DATE) {
            showNotification(NotificationType.WARNING, i18next.t('actions.startDateAfterEndDate'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.reportError'));
        }
        store.dispatch(reportSlice.actions.fetchReportsFailed());
    });
}

export async function generateFuelLogReportAction(data) {
    store.dispatch(reportSlice.actions.fetchReportsStart());
    return generateFuelLogReport(data).then((response) => {
        store.dispatch(reportSlice.actions.fetchReportsVoidSuccess());
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'fuel-log-report-' + data.fuelTankId + '.csv');
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.START_DATE_AFTER_END_DATE) {
            showNotification(NotificationType.WARNING, i18next.t('actions.startDateAfterEndDate'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.reportError'));
        }
        store.dispatch(reportSlice.actions.fetchReportsFailed());
    });
}

export async function generateUserWorkMonthReportAction(data) {
    store.dispatch(reportSlice.actions.fetchReportsStart());
    return generateUserWorkMonthReport(data).then((response) => {
        store.dispatch(reportSlice.actions.fetchReportsVoidSuccess());
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'monthly-user-report-' + data.userId + '.' + data.format.toLowerCase());
        document.body.appendChild(link);
        link.click();
    }).catch(() => {
        showNotification(NotificationType.DANGER, i18next.t('actions.reportError'));
        store.dispatch(reportSlice.actions.fetchReportsFailed());
    });
}
