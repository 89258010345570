import React, { useState } from 'react';
import './DeviceAdd.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import { addDeviceAction } from '../../../redux/actions/deviceActions';
import TextArea from '../../../components/TextArea/TextArea';
import DeviceCondition from '../../../constants/DeviceCondition';
import PowerType from '../../../constants/PowerType';
import { useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from 'react-icons/md';
import UserSelect from '../../../components/UserSelect/UserSelect';
import { useSelector } from 'react-redux';
import UserRole from '../../../constants/UserRole';
import { isNumberValid } from '../../../utils/validationUtils';

function DeviceAdd() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const users = useSelector((state) => state.user.users);
  const warehouseUsers = users && users.length > 0 && users.filter(u => u.role === UserRole.WAREHOUSE);
  const initialAssigneeId = warehouseUsers && warehouseUsers.length > 0 ? warehouseUsers[0].id : user.id;

  const deviceConditionOptions = Object.keys(DeviceCondition).map(key => ({value: key, label: t("common.deviceCondition." + key)}));
  const powerTypeOptions = Object.keys(PowerType).map(key => ({value: key, label: t("common.powerType." + key)}));

  const [deviceName, setDeviceName] = useState("");
  const [deviceNumber, setDeviceNumber] = useState("");
  const [deviceDescription, setDeviceDescription] = useState("");
  const [deviceCondition, setDeviceCondition] = useState(deviceConditionOptions.find(d => d.value === DeviceCondition.NEW));
  const [powerType, setPowerType] = useState(powerTypeOptions.find(pt => pt.value === PowerType.MANUAL));
  const [deviceMileage, setDeviceMileage] = useState("");
  const [assigneeId, setAssigneeId] = useState(initialAssigneeId);
  const [error, setError] = useState(null);
  
  const onSubmit = () => {
    if (deviceMileage && !isNumberValid(deviceMileage)) {
      setError(t('error.deviceMileageFormat'));
    } else {
      setError(null);
      addDeviceAction({
        deviceName, 
        deviceNumber,
        deviceDescription,
        deviceCondition: deviceCondition.value,
        powerType: powerType.value,
        mileage: deviceMileage,
        assigneeId: assigneeId
      }, () => navigate("/devices"));
    }
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer">
        <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
        <div className="nestedScreenTitle">
          {t("deviceManagement.addTitle")}
        </div>
      </div>
    )
  }

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      <TextInput  value={deviceName} 
                  onChange={setDeviceName} 
                  label={t("tableCommons.deviceNameLabel")} 
                  className="halfWidth"
                  maxLength={255} />
      <TextInput  value={deviceNumber} 
                  onChange={setDeviceNumber} 
                  label={t("tableCommons.deviceNumberLabel")} 
                  className="halfWidth"
                  maxLength={60} />
      <TextArea value={deviceDescription} 
                onChange={setDeviceDescription} 
                label={t("tableCommons.deviceDescriptionLabel")} 
                className="halfWidth"
                maxLength={1000} />
      <CustomDropdown value={deviceCondition} 
                      onChange={setDeviceCondition} 
                      options={deviceConditionOptions} 
                      label={t("tableCommons.deviceConditionLabel")} 
                      className="halfWidth"/>
      <CustomDropdown value={powerType} 
                      onChange={setPowerType} 
                      options={powerTypeOptions} 
                      label={t("tableCommons.powerTypeLabel")} 
                      className="halfWidth"/>
      {powerType && powerType.value === PowerType.FUEL &&
        <TextInput  value={deviceMileage} 
                    onChange={setDeviceMileage} 
                    label={t("tableCommons.deviceMileage")} 
                    className="halfWidth"
                    maxLength={60} />
      }
      <UserSelect label={t("tableCommons.assigneeLabel")} 
                  value={assigneeId} 
                  onChange={setAssigneeId} 
                  className="deviceAddUserField" />
      {error && 
        <div className="errorMessage">
          {error}
        </div>
      }
      <div className="formButtons halfWidth">
        <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!deviceName || !deviceNumber}/>
      </div>
    </div>
  );
}

export default DeviceAdd;
