import React from 'react';
import './CustomPopup.css';
import CustomButton from '../CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';

function CustomPopup({label, onAccept, close, customBody, isAcceptDisabled, withoutAccept}) {
  const {t} = useTranslation();

  return (
    <div className="customPopupOverlay" onClick={close}>
      <div className="customPopupContainer" onClick={e => e.stopPropagation()}>
        {label &&
          <div className="customPopupTitle">
            {label}
          </div>
        }
        {customBody ? customBody : null}
        <div className="customPopupButtons">
          <CustomButton label={t("common.cancel")} onClick={close}/>
          {!withoutAccept && <CustomButton label={t("common.accept")} onClick={onAccept} isDisabled={isAcceptDisabled}/>}
        </div>
      </div>
    </div>
  );
}

export default CustomPopup;
