import React from 'react';
import './CustomButton.css';

function CustomButton({onClick, label, isDisabled, style}) {

  const classNames = isDisabled ? "customButton disabled" : "customButton";

  return (
    <div className={classNames} onClick={isDisabled ? null : onClick} style={style ? style : {}}>
      {label}
    </div>
  );
}

export default CustomButton;
