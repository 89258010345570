import React, { useEffect, useRef } from 'react';
import './DocumentsList.css';
import {useTranslation} from "react-i18next";
import { tableIcons } from '../../utils/tableUtils';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import { MdFileDownload, MdDeleteOutline } from "react-icons/md";

function DocumentsList({fetchAction, documents, onDocumentDelete}) {

  const {t} = useTranslation();

  useEffect(() => { 
    fetchAction();
  }, []);

  const onDelete = (doc) => {
    onDocumentDelete(doc.id);
  }

  const onDownload = (doc) => {
    window.open(doc.documentContent, "_blank")
  }

  const mappedDocuments = !documents ? [] : documents.map(d => ({
    ...d,
    creationDate: moment(d.creationDate).format("DD/MM/YYYY HH:mm"),
    download: <MdFileDownload className="tableIcon" onClick={() => onDownload(d)} />,
    delete: <MdDeleteOutline className="tableIcon" onClick={() => onDelete(d)} color={'#e51b22'}/>
  }))

  return (
    <MaterialTable
      icons={tableIcons}
      columns={[
        { title: t("tableCommons.documentNameLabel"), field: 'documentName' },
        { title: t("tableCommons.creationDateLabel"), field: 'creationDate' },
        { title: t("tableCommons.downloadLabel"), field: 'download', width: "100px" },
        { title: t("tableCommons.deleteLabel"), field: 'delete', width: "100px" }
      ]}
      data={mappedDocuments}
      title={null}
      options={{
        paging: false,
        sorting: false,
        search: false
      }}
      localization={{
        body: {
            emptyDataSourceMessage: t("tableCommons.noRecords")
        },
      }}
    />
  );
}

export default DocumentsList;
