import store from '../store';
import { addConstruction, editConstruction, getAllConstructions, getConstructionById } from '../../services/constructionService';
import { constructionSlice } from '../features/constructionSlice';
import i18next from "i18next";
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import ErrorCode from '../../constants/ErrorCode';

export async function fetchConstructionsAction(status) {
    store.dispatch(constructionSlice.actions.fetchConstructionsStart());
    return getAllConstructions(status).then((constructionsFetchResponse) => {
        store.dispatch(constructionSlice.actions.fetchConstructionsSuccess(constructionsFetchResponse.data));
    }).catch((error) => {
        store.dispatch(constructionSlice.actions.fetchConstructionsFailed());
    });
}

export async function fetchContextConstructionAction(constructionId) {
    store.dispatch(constructionSlice.actions.fetchConstructionsStart());
    return getConstructionById(constructionId).then((constructionFetchResponse) => {
        store.dispatch(constructionSlice.actions.fetchContextConstructionSuccess(constructionFetchResponse.data));
    }).catch((error) => {
        store.dispatch(constructionSlice.actions.fetchConstructionsFailed());
    });
}

export async function clearContextConstructionAction() {
    store.dispatch(constructionSlice.actions.fetchConstructionsStart());
    store.dispatch(constructionSlice.actions.fetchContextConstructionSuccess(null));
    store.dispatch(constructionSlice.actions.fetchConstructionsFailed());
}

export async function addConstructionAction(data, callback) {
    store.dispatch(constructionSlice.actions.fetchConstructionsStart());
    return addConstruction(data).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.addConstructionSuccess'));
        if (callback)
            callback();
    }).catch((error) => {
        store.dispatch(constructionSlice.actions.fetchConstructionsFailed());
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.CONSTRUCTION_NAME_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('actions.constructionNameAlreadyExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.addConstructionError'));
        }
    });
}

export async function editConstructionAction(data) {
    store.dispatch(constructionSlice.actions.fetchConstructionsStart());
    return editConstruction(data).then((updatedConstruction) => {
        store.dispatch(constructionSlice.actions.fetchContextConstructionSuccess(updatedConstruction.data));
        showNotification(NotificationType.SUCCESS, i18next.t('actions.editConstructionSuccess'));
    }).catch((error) => {
        store.dispatch(constructionSlice.actions.fetchConstructionsFailed());
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.CONSTRUCTION_NAME_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('actions.constructionNameAlreadyExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.editConstructionError'));
        }
    });
}

