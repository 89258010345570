import React from 'react';
import './AssignedUser.css';

function AssignedUser({user, label}) {

  if (!user)
    return null;

  const avatar = user.avatar ? user.avatar : "/blankAvatar.png";

  return (
    <div className="assignedUserContainer">
      {label &&
        <div className="assignedUserLabel">
          {label}
        </div>
      }
      <div className="assignedUserInfoContainer">
        <img src={avatar} alt="avatar" className="assignedUserAvatar"/>
        <div className = "assignedUserText">
          {user.username}
        </div>
      </div>
    </div>
  );
}

export default AssignedUser;
