// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideNavigation {
  display: flex;
  width: 200px;
  height: calc(100vh - 100px);
  flex-direction: column;
  background-color: #f6f6f5;
  z-index: 1002;
  padding-top: 10px;
  box-shadow: 4px 0px 6px 0px rgba(157, 157, 157, 1);
}

.sideNavigationLinkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  text-decoration: none;
  height: 40px;
}

.sideNavigationLink {
  color: #000000;
  font-size: 14px;
  margin-left: 5px;
}

.sideNavigationLinkActive {
  color: #e51b22;
  font-size: 24px;
  background-color: #f5cacb;
}

.sideNavigationLinkIconWrapper {
  display: flex;
  width: 40px;
  justify-content: center;
  align-content: center;
}

.sideNavigationLinkIcon {
  max-height: 30px;
  max-width: 30px;
}

.sideNavigationLinkIconActive {
  filter: grayscale(100%);
}
`, "",{"version":3,"sources":["webpack://./src/components/SideNavigation/SideNavigation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,2BAA2B;EAC3B,sBAAsB;EACtB,yBAAyB;EACzB,aAAa;EACb,iBAAiB;EAGjB,kDAAkD;AACpD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".sideNavigation {\n  display: flex;\n  width: 200px;\n  height: calc(100vh - 100px);\n  flex-direction: column;\n  background-color: #f6f6f5;\n  z-index: 1002;\n  padding-top: 10px;\n  -webkit-box-shadow: 4px 0px 6px 0px rgba(157, 157, 157, 1);\n  -moz-box-shadow: 4px 0px 6px 0px rgba(157, 157, 157, 1);\n  box-shadow: 4px 0px 6px 0px rgba(157, 157, 157, 1);\n}\n\n.sideNavigationLinkContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 0 10px;\n  cursor: pointer;\n  text-decoration: none;\n  height: 40px;\n}\n\n.sideNavigationLink {\n  color: #000000;\n  font-size: 14px;\n  margin-left: 5px;\n}\n\n.sideNavigationLinkActive {\n  color: #e51b22;\n  font-size: 24px;\n  background-color: #f5cacb;\n}\n\n.sideNavigationLinkIconWrapper {\n  display: flex;\n  width: 40px;\n  justify-content: center;\n  align-content: center;\n}\n\n.sideNavigationLinkIcon {\n  max-height: 30px;\n  max-width: 30px;\n}\n\n.sideNavigationLinkIconActive {\n  filter: grayscale(100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
