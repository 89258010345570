import store from '../store';
import { addFuelTank, editFuelTank, getAllFuelTanks, getFuelTankById } from '../../services/fuelTankService';
import { fuelTankSlice } from '../features/fuelTankSlice';
import i18next from "i18next";
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import ErrorCode from '../../constants/ErrorCode';

export async function fetchFuelTanksAction(status) {
    store.dispatch(fuelTankSlice.actions.fetchFuelTanksStart());
    return getAllFuelTanks(status).then((fuelTanksFetchResponse) => {
        store.dispatch(fuelTankSlice.actions.fetchFuelTanksSuccess(fuelTanksFetchResponse.data));
    }).catch((error) => {
        store.dispatch(fuelTankSlice.actions.fetchFuelTanksFailed());
    });
}

export async function fetchContextFuelTankAction(fuelTankId) {
    store.dispatch(fuelTankSlice.actions.fetchFuelTanksStart());
    return getFuelTankById(fuelTankId).then((fuelTankFetchResponse) => {
        store.dispatch(fuelTankSlice.actions.fetchContextFuelTankSuccess(fuelTankFetchResponse.data));
    }).catch((error) => {
        store.dispatch(fuelTankSlice.actions.fetchFuelTanksFailed());
    });
}

export async function clearContextFuelTankAction(fuelTankId) {
    store.dispatch(fuelTankSlice.actions.fetchFuelTanksStart());
    store.dispatch(fuelTankSlice.actions.fetchContextFuelTankSuccess(null));
    store.dispatch(fuelTankSlice.actions.fetchFuelTanksFailed());
}

export async function addFuelTankAction(data, callback) {
    store.dispatch(fuelTankSlice.actions.fetchFuelTanksStart());
    return addFuelTank(data).then(() => {
        if (callback)
            callback();
        showNotification(NotificationType.SUCCESS, i18next.t('actions.addFuelTankSuccess'));
    }).catch((error) => {
        store.dispatch(fuelTankSlice.actions.fetchFuelTanksFailed());
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.FUEL_TANK_NAME_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('actions.fuelTankNameAlreadyExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.addFuelTankError'));
        }
    });
}

export async function editFuelTankAction(data) {
    store.dispatch(fuelTankSlice.actions.fetchFuelTanksStart());
    return editFuelTank(data).then((updatedFuelTank) => {
        store.dispatch(fuelTankSlice.actions.fetchContextFuelTankSuccess(updatedFuelTank.data));
        showNotification(NotificationType.SUCCESS, i18next.t('actions.editFuelTankSuccess'));
    }).catch((error) => {
        store.dispatch(fuelTankSlice.actions.fetchFuelTanksFailed());
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.FUEL_TANK_NAME_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('actions.fuelTankNameAlreadyExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.editFuelTankError'));
        }
    });
}

