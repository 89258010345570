import axios from 'axios';
import i18next from "i18next";
import ErrorCode from '../constants/ErrorCode';
import LocalStorageKey from '../constants/LocalStorageKey';
import { logoutAction } from '../redux/actions/authActions';
import { NotificationType, showNotification } from '../utils/notificationUtils';

const mainAxiosService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
});

const blobAxiosService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json'
  },
});

const requestInterceptorSuccessHandler = (config) => {
  const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
  if (accessToken)
    config.headers["Authorization"] = accessToken;
  return config;
}

const requestInterceptorErrorHandler = (error) => {
  return Promise.reject(error);
}

const responseInterceptorSuccessHandler = (response) => {
  if (Object.keys(response.headers).indexOf('refresh-token') >= 0) {
    localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, response.headers['refresh-token']);
  } 
  return response;
}

const responseInterceptorErrorHandler = (error) => {
  if (error && error.response && error.response.data === ErrorCode.TOKEN_EXPIRED) {
    logoutAction();
    showNotification(NotificationType.WARNING, i18next.t('error.sessionExpired'));
  }
  return Promise.reject(error);
}

mainAxiosService.interceptors.request.use(requestInterceptorSuccessHandler, requestInterceptorErrorHandler);
mainAxiosService.interceptors.response.use(responseInterceptorSuccessHandler, responseInterceptorErrorHandler);

blobAxiosService.interceptors.request.use(requestInterceptorSuccessHandler, requestInterceptorErrorHandler);
blobAxiosService.interceptors.response.use(responseInterceptorSuccessHandler, responseInterceptorErrorHandler);

export default mainAxiosService;
export {blobAxiosService};