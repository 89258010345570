import React, { useState, useEffect } from 'react';
import './UserAdd.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Language from '../../../constants/Language';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import { addUserAction } from '../../../redux/actions/userActions';
import UserRole from '../../../constants/UserRole';
import { useParams, useNavigate } from "react-router-dom";
import { clearContextTenantAction, fetchContextTenantAction } from '../../../redux/actions/tenantActions';
import { useSelector } from 'react-redux';
import { MdKeyboardBackspace } from "react-icons/md";
import { isEmailValid, isPasswordValid } from '../../../utils/validationUtils';

function UserAdd() {

  const {t} = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const tenantId = params.tenantId;
  const contextTenant = useSelector((state) => state.tenant.contextTenant);

  const languageOptions = Object.keys(Language).map(key => ({value: key, label: t("common.language." + key)}));
  const roleOptions = Object.keys(UserRole)
    .filter(role => role !== UserRole.SUPER_ADMIN)
    .map(key => ({value: key, label: t("common.userRole." + key)}));
  
  const [username, setUsername] = useState("");
  const [language, setLanguage] = useState(null);
  const [role, setRole] = useState(roleOptions.find(s => s.value === UserRole.USER));
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [privatePhoneNumber, setPrivatePhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => { 
    return clearContextTenantAction;
  }, []);

  useEffect(() => {
    if (error !== null) {
      setError(null);
    }
  }, [username, email, password]);

  useEffect(() => { 
    if (tenantId && !contextTenant) {
      fetchContextTenantAction(tenantId);
    }
    
    if (contextTenant) {
      setLanguage(languageOptions.find(l => l.value === Language[contextTenant.defaultLanguage]));
    }

  }, [contextTenant, tenantId]);

  const onSubmit = () => {
    if (!isPasswordValid(password)) {
      setError(t('error.passwordFormatError'));
    } else if (email && email.length > 0 && !isEmailValid(email)) {
      setError(t('error.emailFormat'));
    } else {
      setError(null);
      addUserAction({
        tenantId: contextTenant.id,
        username,
        language: language.value,
        role: role.value,
        email,
        phoneNumber,
        privatePhoneNumber,
        password
      }, () => navigate("/users?tenantId=" + contextTenant.id));
    }
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer">
        <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
        {contextTenant && 
          <div className="nestedScreenTitle">
            {t("userManagement.addTitle") + contextTenant.tenantName}
          </div>
        }
      </div>
    )
  }

  const isDisabled = !username || !password;

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      <TextInput  value={username} 
                  onChange={setUsername} 
                  label={t("common.username")} 
                  maxLength={255} 
                  className="halfWidth"/>
      <TextInput  value={password} 
                  onChange={setPassword} 
                  label={t("login.password")} 
                  maxLength={255} 
                  className="halfWidth"/>
      <CustomDropdown value={role} 
                      onChange={setRole} 
                      options={roleOptions} 
                      label={t("common.role")} 
                      className="halfWidth"/>
      <TextInput  value={email} 
                  onChange={setEmail} 
                  label={t("common.email")} 
                  maxLength={255} 
                  className="halfWidth"/>
      <TextInput  value={phoneNumber} 
                  onChange={setPhoneNumber} 
                  label={t("tableCommons.phoneNumberLabel")} 
                  maxLength={255} 
                  className="halfWidth"/>
      <TextInput  value={privatePhoneNumber} 
                  onChange={setPrivatePhoneNumber} 
                  label={t("tableCommons.privatePhoneNumberLabel")} 
                  maxLength={255} 
                  className="halfWidth"/>
      <CustomDropdown value={language} 
                      onChange={setLanguage} 
                      options={languageOptions} 
                      label={t("tableCommons.languageLabel")} 
                      className="halfWidth"/>
      {error && 
        <div className="errorMessage">
          {error}
        </div>
      }
      <div className="formButtons halfWidth">
        <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={isDisabled}/>
      </div>
    </div>
  );
}

export default UserAdd;
