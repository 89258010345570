import store from '../store';
import { getAllFuelLogs, deleteFuelLog } from '../../services/fuelLogService';
import { fuelLogSlice } from '../features/fuelLogSlice';

export async function fetchFuelLogsAction(fuelTankId) {
    store.dispatch(fuelLogSlice.actions.fetchFuelLogsStart());
    return getAllFuelLogs(fuelTankId).then((fuelLogsFetchResponse) => {
        store.dispatch(fuelLogSlice.actions.fetchFuelLogsSuccess(fuelLogsFetchResponse.data));
    }).catch((error) => {
        store.dispatch(fuelLogSlice.actions.fetchFuelLogsFailed());
    });
}

export async function deleteFuelLogAction(fuelLogId, fuelTankId) {
    store.dispatch(fuelLogSlice.actions.fetchFuelLogsStart());
    return deleteFuelLog(fuelLogId).then(() => {
        return getAllFuelLogs(fuelTankId);
    }).then((fuelLogsFetchResponse) => {
        store.dispatch(fuelLogSlice.actions.fetchFuelLogsSuccess(fuelLogsFetchResponse.data));
    }).catch((error) => {
        store.dispatch(fuelLogSlice.actions.fetchFuelLogsFailed());
    });
}