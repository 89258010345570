import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    users: [],
    userDocuments: [],
    userDevices: [],
    contextUser: null
  },
  reducers: {
    fetchUsersStart: (state) => {
        state.isLoading = true
    },
    fetchUsersSuccess: (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
    },
    fetchContextUserSuccess: (state, action) => {
      state.contextUser = action.payload;
      state.isLoading = false;
    },
    fetchUserDocumentsSuccess: (state, action) => {
      state.userDocuments = action.payload;
      state.isLoading = false;
    },
    fetchUserDevicesSuccess: (state, action) => {
      state.userDevices = action.payload;
      state.isLoading = false;
    },
    fetchUsersFailed: (state) => {
        state.isLoading = false
    },
    fetchUsersVoidSuccess: (state) => {
      state.isLoading = false;
    },
  },
})

export const { 
  fetchUsersStart, 
  fetchUsersSuccess, 
  fetchUserDocumentsSuccess,
  fetchUserDevicesSuccess,
  fetchUserssFailed
} = userSlice.actions

export default userSlice.reducer