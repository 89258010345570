import { createSlice } from '@reduxjs/toolkit'

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState: {
    isLoading: false,
    tenants: [],
    contextTenant: null
  },
  reducers: {
    fetchTenantsStart: (state) => {
        state.isLoading = true
    },
    fetchTenantsSuccess: (state, action) => {
        state.tenants = action.payload;
        state.isLoading = false;
    },
    fetchContextTenantSuccess: (state, action) => {
      state.contextTenant = action.payload;
      state.isLoading = false;
  },
    fetchTenantsFailed: (state) => {
        state.isLoading = false
    },
  },
})

export const { 
  fetchTenantsStart, 
  fetchTenantsSuccess, 
  fetchContextTenantSuccess,
  fetchTenantsFailed
} = tenantSlice.actions

export default tenantSlice.reducer