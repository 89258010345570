// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.textInputLabel {
  color: #e51b22;
  margin-bottom: 5px;
  font-weight: 700;
}

.textInput {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #f3f3f3;
  border: none;
  border-bottom: 1px solid #000000;
  padding: 10px;
  font-size: 16px;
}

.textInput:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/TextInput/TextInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,yBAAyB;EACzB,YAAY;EACZ,gCAAgC;EAChC,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".textInputContainer {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  margin-left: 20px;\n  margin-right: 20px;\n}\n\n.textInputLabel {\n  color: #e51b22;\n  margin-bottom: 5px;\n  font-weight: 700;\n}\n\n.textInput {\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n  background-color: #f3f3f3;\n  border: none;\n  border-bottom: 1px solid #000000;\n  padding: 10px;\n  font-size: 16px;\n}\n\n.textInput:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
