import React, {useEffect} from 'react';
import './UserSelect.css';
import Select from 'react-select';
import { fetchUsersAction } from '../../redux/actions/userActions';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";
import UserStatus from '../../constants/UserStatus';

function UserSelect({value, onChange, label, className}) {

  const {t} = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const users = useSelector((state) => state.user.users);

  useEffect(() => { 
    if (user)
      fetchUsersAction(user.tenant.id, null);
  }, [user]);

  const styles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#e51b22' : "#ffffff",
      "&:hover": {
        backgroundColor: "#f5cacb",
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 10000 })
  };

  const getUserLabel = (u) => {
    const avatar = u.avatar ? u.avatar : "/blankAvatar.png";
    return (
      <div className = "userSelectLabel">
        <img src={avatar} alt="avatar" className="userSelectAvatar"/>
        <div className = "userSelectLabelText">
          {u.username}
        </div>
      </div>
    );
  }

  const onSelectChange = (event) => {
    onChange(event.value)
  }

  const activeUsers = !users ? [] : users.filter(u => u.status === UserStatus.ACTIVE);
  const sortedUsers = !activeUsers ? [] : [...activeUsers].sort((a, b) => a.username.localeCompare(b.username, user.language));
  const options = sortedUsers
    .map(u => ({
      value: u.id,
      label: getUserLabel(u)
    }));

  const getCurrentValue = () => {
    if (!users || users.length === 0) {
      return null;
    } else {
      return options.find(u => u.value === value);
    }
  }

  const filterOption = (option, inputValue) => {
    const { value } = option;
    const searchValue = inputValue ? inputValue : "";
    const optionUser = sortedUsers.find(u => u.id === value);
    return optionUser && optionUser.username && optionUser.username.toLowerCase().includes(searchValue.toLowerCase());
  };

  return (
    <div key={label} className={"customUserDropdownContainer" + (className ? (" " + className) : "")}>
      <div className="customUserDropdownLabel">
        {label}
      </div>
      <Select 
        className="customUserDropdown"
        isSearchable={true}
        filterOption={filterOption}
        value={getCurrentValue()}
        onChange={onSelectChange} 
        options={options} 
        menuPortalTarget={document.body}  
        styles={styles}
        classNamePrefix="react-user-select"
        placeholder={t("common.select")}
      />
    </div>
  );
}

export default UserSelect;
