import React, {useState} from 'react';
import './TopBar.css';
import { MdChevronRight } from "react-icons/md";
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";
import { logoutAction } from '../../redux/actions/authActions';
import LanguageChange from '../LanguageChange/LanguageChange';
import CustomUserRole from '../CustomUserRole/CustomUserRole';

function TopBar() {

  const {t} = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const avatar = user.avatar ? user.avatar : "/blankAvatar.png";

  const renderUserInfo = () => {
    return (
      <div className="topBarMenuUserInfoContainer">
        <div className="topBarMenuUserInfoUsername">
          <img src={avatar} alt="avatar" className="topBarMenuUserInfoAvatar"/>
          <div>
            {user.username}
            <CustomUserRole role={user.role} />
          </div>
        </div>
      </div>
    );
  }

  const renderMenu = () => {
      return (
        <div className="topBarMenuContainer" style={isMenuOpen ? {display: "flex"} : null}>
          <div className="topBarMenuItem" style={{borderBottom: "none"}} onClick={logoutAction}>
            {t("login.logout")}
          </div>
        </div>
      );
  }

  return (
    <div className="topBar">
      <img src={"logoBig.png"} className="topBarLogo" alt="logo" />
      <div className="topBarUserInfo">
        <LanguageChange />
        {renderUserInfo()}
        <MdChevronRight className={"topBarMenuIcon" + (isMenuOpen ? " topBarMenuIconOpen" : "")} onClick={() => setIsMenuOpen(!isMenuOpen)}/>
      </div>
      {renderMenu()}
    </div>
  );
}

export default TopBar;
