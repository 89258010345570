import React, { useEffect } from 'react';
import './UserDevices.css';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../../utils/tableUtils';
import moment from 'moment';
import { fetchUserDevicesAction } from '../../../redux/actions/userActions';
import { useNavigate } from "react-router-dom";

function UserDevices({contextUser}) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const userDevices = useSelector((state) => state.user.userDevices);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => { 
    if(contextUser && contextUser.id)
      fetchUserDevicesAction(contextUser.id);
  }, [contextUser]);

  const onRowClick = (event, rowData) => {
    navigate("/devices/edit/" + rowData.id);
  }

  const mappedDevices = !userDevices ? [] : userDevices.map(device => ({
    ...device,
    status: t("common.status." + device.status),
    deviceCondition: t("common.deviceCondition." + device.deviceCondition),
    powerType: t("common.powerType." + device.powerType),
    creationDate: moment(device.creationDate).format("DD/MM/YYYY HH:mm")
  }))

  return (
    <div className="nestedInnerTable">
      <MaterialTable
        icons={tableIcons}
        columns={[
          { 
            title: t("tableCommons.deviceNameLabel"), 
            field: 'deviceName',
            customSort: (a, b) => a.deviceName.localeCompare(b.deviceName, user.language) 
          },
          { 
            title: t("tableCommons.deviceNumberLabel"), 
            field: 'deviceNumber',
            customSort: (a, b) => a.deviceNumber.localeCompare(b.deviceNumber, user.language) 
          },
          { title: t("tableCommons.creationDateLabel"), field: 'creationDate' },
          { title: t("tableCommons.powerTypeLabel"), field: 'powerType' },
          { title: t("tableCommons.deviceConditionLabel"), field: 'deviceCondition' }
        ]}
        data={mappedDevices}
        title={null}
        onRowClick={onRowClick}
        options={{
          paging:true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100],
        }}
        localization={{
          body: {
              emptyDataSourceMessage: t("tableCommons.noRecords")
          },
          toolbar: {
            searchPlaceholder: t("common.search")
          },
          pagination: {
            labelRowsSelect: t("tableCommons.rows")
          }
        }}
      />
    </div>
  );
}

export default UserDevices;
