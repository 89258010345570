import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import translations_pl from "./translations/pl.json";
import translations_en from "./translations/en.json";
import {initializeFirebaseApp} from "./utils/firebaseUtils";
import { registerLocale } from "react-datepicker";
import en from 'date-fns/locale/en-GB';
import pl from 'date-fns/locale/pl';

registerLocale('en', en);
registerLocale('pl', pl);

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'pl',
  resources: {
      en: { translation: translations_en },
      pl: { translation: translations_pl }
  },
});

initializeFirebaseApp();

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
