import React from 'react';
import './CustomDatePicker.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function CustomDatePicker({value, onChange, label, className, withoutTime}) {
  const {t} = useTranslation();
  const user = useSelector((state) => state.auth.user);

  return (
    <div key={label} className={"customDatePickerContainer" + (className ? (" " + className) : "")}>
      <div className="customDatePickerLabel">
        {label}
      </div>
      <DatePicker 
        showTimeSelect={!withoutTime}
        selected={value}
        onChange={onChange}
        dateFormat={withoutTime ? "dd/MM/yyyy" : "Pp"}
        className={"customDatePicker"}
        timeCaption={t("common.timeLabel")}
        locale={(user && user.language) ? user.language.toLowerCase() : 'pl'}/>
    </div>
  );
}

export default CustomDatePicker;
