import React from 'react';
import './App.css';
import MainRouter from './routers/MainRouter';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import FetchingIndicator from './components/FetchingIndicator/FetchingIndicator';

function App() {
  return (
    <Provider store={store}>
      <div className="app">
        <MainRouter /> 
        <ReactNotifications style={{ zIndex: 1000 }} />
        <FetchingIndicator />
      </div>
    </Provider>
  );
}

export default App;
