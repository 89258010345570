import React from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import WithTopBarAndMenuHoc from '../hoc/WithTopBarAndMenuHoc/WithTopBarAndMenuHoc';
import ConstructionAdd from '../screens/ConstructionManagement/ConstructionAdd/ConstructionAdd';
import ConstructionEdit from '../screens/ConstructionManagement/ConstructionEdit/ConstructionEdit';
import ConstructionManagement from '../screens/ConstructionManagement/ConstructionManagement';
import DeviceAdd from '../screens/DeviceManagement/DeviceAdd/DeviceAdd';
import DeviceEdit from '../screens/DeviceManagement/DeviceEdit/DeviceEdit';
import DeviceManagement from '../screens/DeviceManagement/DeviceManagement';
import FuelTankAdd from '../screens/FuelTankManagement/FuelTankAdd/FuelTankAdd';
import FuelTankEdit from '../screens/FuelTankManagement/FuelTankEdit/FuelTankEdit';
import FuelTankManagement from '../screens/FuelTankManagement/FuelTankManagement';
import PageNotFound from '../screens/PageNotFound/PageNotFound';
import Reports from '../screens/Reports/Reports';
import Calendar from '../screens/Calendar/Calendar';
import UserAdd from '../screens/UserManagement/UserAdd/UserAdd';
import UserEdit from '../screens/UserManagement/UserEdit/UserEdit';
import UserManagement from '../screens/UserManagement/UserManagement';

function AuthorizedUserRouter() {

  const WrappedDeviceManagement = WithTopBarAndMenuHoc(DeviceManagement);
  const WrappedAddDeviceForm = WithTopBarAndMenuHoc(DeviceAdd);
  const WrappedDeviceDetails = WithTopBarAndMenuHoc(DeviceEdit);
  const WrappedUserManagement = WithTopBarAndMenuHoc(UserManagement);
  const WrappedAddUserForm = WithTopBarAndMenuHoc(UserAdd);
  const WrappedUserDetails = WithTopBarAndMenuHoc(UserEdit);
  const WrappedConstructionManagement = WithTopBarAndMenuHoc(ConstructionManagement);
  const WrappedAddConstructionForm = WithTopBarAndMenuHoc(ConstructionAdd);
  const WrappedConstructionDetails = WithTopBarAndMenuHoc(ConstructionEdit);
  const WrappedFuelTankManagement = WithTopBarAndMenuHoc(FuelTankManagement);
  const WrappedAddFuelTankForm = WithTopBarAndMenuHoc(FuelTankAdd);
  const WrappedFuelTankDetails = WithTopBarAndMenuHoc(FuelTankEdit);
  const WrappedReports = WithTopBarAndMenuHoc(Reports);
  const WrappedCalendar = WithTopBarAndMenuHoc(Calendar);

  return (
    <Routes>
      <Route path="/users" element={<WrappedUserManagement />} />
      <Route path="/users/add/:tenantId" element={<WrappedAddUserForm />} />
      <Route path="/users/edit/:userId" element={<WrappedUserDetails />} />
      <Route path="/constructions" element={<WrappedConstructionManagement />} />
      <Route path="/constructions/add" element={<WrappedAddConstructionForm />} />
      <Route path="/constructions/edit/:constructionId" element={<WrappedConstructionDetails />} />
      <Route path="/devices" element={<WrappedDeviceManagement />} />
      <Route path="/devices/add" element={<WrappedAddDeviceForm />} />
      <Route path="/devices/edit/:deviceId" element={<WrappedDeviceDetails />} />
      <Route path="/fuelTanks" element={<WrappedFuelTankManagement />} />
      <Route path="/fuelTanks/add" element={<WrappedAddFuelTankForm />} />
      <Route path="/fuelTanks/edit/:fuelTankId" element={<WrappedFuelTankDetails />} />
      <Route path="/reports" element={<WrappedReports />} />
      <Route path="/calendar" element={<WrappedCalendar />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AuthorizedUserRouter;
