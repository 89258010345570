import React, { useState, useEffect, useRef } from 'react';
import './UserEdit.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Language from '../../../constants/Language';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import UserStatus from '../../../constants/UserStatus';
import UserRole from '../../../constants/UserRole';
import { 
  addUserDocumentAction,
  clearContextUserAction, 
  deleteUserDocumentAction, 
  editUserAction, 
  fetchContextUserAction, 
  fetchUserDocumentsAction 
} from '../../../redux/actions/userActions';
import WorkLogList from '../../WorkLogList/WorkLogList';
import { useSelector } from 'react-redux';
import DocumentsList from '../../DocumentsList/DocumentsList';
import PasswordChange from '../../PasswordChange/PasswordChange';
import UserDevices from '../UserDevices/UserDevices';
import { useParams, useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import { isEmailValid } from '../../../utils/validationUtils';
import { uploadFile } from '../../../utils/firebaseUtils';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import QRCode from 'react-qr-code';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import { addWorkLogAction } from '../../../redux/actions/workLogActions';
import moment from 'moment';
import ConstructionStatus from '../../../constants/ConstructionStatus';
import { fetchConstructionsAction } from '../../../redux/actions/constructionActions';
import { addUserDaysOffAction } from '../../../redux/actions/userDayOffActions';
import UserDaysOffList from './UserDaysOffList';
import DayOffType from '../../../constants/DayOffType';

function UserEdit() {
  const BottomSection = {
    EDIT: "EDIT",
    DEVICES: "DEVICES",
    WORK_LOG: "WORK_LOG",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    DOCUMENTS: "DOCUMENTS",
    DAYS_OFF: "DAYS_OFF",
    QR: "QR",
  }

  const DirectorBottomSection = {
    DEVICES: "DEVICES",
    WORK_LOG: "WORK_LOG",
    DOCUMENTS: "DOCUMENTS",
    DAYS_OFF: "DAYS_OFF",
    QR: "QR"
  }

  const SuperAdminBottomSection = {
    EDIT: "EDIT",
    CHANGE_PASSWORD: "CHANGE_PASSWORD"
  }

  const {t} = useTranslation();
  const fileInput = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const userId = params.userId;
  const contextUser = useSelector((state) => state.user.contextUser);
  const languageOptions = Object.keys(Language).map(key => ({value: key, label: t("common.language." + key)}));
  const statusOptions = Object.keys(UserStatus).map(key => ({value: key, label: t("common.status." + key)}));
  const roleOptions = Object.keys(UserRole)
    .filter(role => role !== UserRole.SUPER_ADMIN)
    .map(key => ({value: key, label: t("common.userRole." + key)}));
    const constructions = useSelector((state) => state.construction.constructions);
    const constructionOptions = (constructions && constructions.length > 0) ? 
      constructions.map(c => ({
        value: c.id, 
        label: c.constructionName
      })) : 
      [];
  const dayOffTypeOptions = Object.keys(DayOffType).map(key => ({value: key, label: t("common.userDayOff." + key)}));

  const [language, setLanguage] = useState(null);
  const [status, setStatus] = useState(null);
  const [role, setRole] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [privatePhoneNumber, setPrivatePhoneNumber] = useState("");
  const [error, setError] = useState(null);
  const [documentIdToDelete, setDocumentIdToDelete] = useState(null);
  const [isAddWorkLogPopupVisible, setIsAddWorkLogPopupVisible] = useState(false);
  const [workLogAddConstructionId, setWorkLogAddConstructionId] = useState(null);
  const [workLogAddStartDate, setWorkLogAddStartDate] = useState(null);
  const [workLogAddEndtDate, setWorkLogAddEndDate] = useState(null);
  const [isAddDaysOffPopupVisible, setIsAddDaysOffPopupVisible] = useState(false);
  const [daysOffAddStartDate, setDaysOffAddStartDate] = useState(null);
  const [daysOffAddEndDate, setDaysOffAddEndDate] = useState(null);
  const [daysOffAddType, setDaysOffAddType] = useState(null);
  const documents = useSelector((state) => state.user.userDocuments);
  const user = useSelector((state) => state.auth.user);

  const getInitialSection = () => {
    if (!user)
      return BottomSection.EDIT;

    switch (user.role) {
      case UserRole.SUPER_ADMIN:
        return SuperAdminBottomSection.EDIT;
      case UserRole.TENANT_DIRECTOR:
        return DirectorBottomSection.DEVICES;
      default:
        return BottomSection.EDIT;
    }
  }

  const [bottomSection, setBottomSection] = useState(getInitialSection());

  useEffect(() => {
    fetchConstructionsAction(ConstructionStatus.ACTIVE);
    if (error !== null) {
      setError(null);
    }
    return clearContextUserAction;
  }, []);

  useEffect(() => { 
    if (userId && !contextUser)
      fetchContextUserAction(userId);

    if (contextUser) {
      setLanguage(languageOptions.find(l => l.value === Language[contextUser.language]));
      setStatus(statusOptions.find(s => s.value === UserStatus[contextUser.status]));
      setRole(roleOptions.find(s => s.value === UserRole[contextUser.role]));
      setEmail(contextUser.email);
      setPhoneNumber(contextUser.phoneNumber);
    }
  }, [contextUser, userId]);

  const onSubmit = () => {
    if (email && email.length > 0 && !isEmailValid(email)) {
      setError(t('error.emailFormat'));
    } else {
      setError(null);
      editUserAction({
        userId: contextUser.id,
        language: language.value,
        status: status.value,
        role: role.value,
        email,
        phoneNumber,
        privatePhoneNumber
      });
    }
  }

  const onDocumentUpload = (e) => {
    const file = e.target.files[0];
    uploadFile(file.name, file).then(response => {
      return addUserDocumentAction({
        userId: contextUser.id,
        documentName: file.name,
        documentContent: response
      }, () => fetchUserDocumentsAction(contextUser.id));
    }).catch(e => {
      console.log("Upload file error: " + e);
    });
  }

  const onDocumentDelete = (userDocumentId) => {
    deleteUserDocumentAction(userDocumentId, () => {
      fetchUserDocumentsAction(contextUser.id)
      setDocumentIdToDelete(null);
    });
  }

  const renderDeleteDocumentPopup = () => {
    if (!documentIdToDelete)
      return null;

    const doc = documents.find(d => d.id === documentIdToDelete);
    return (
      <CustomPopup  label={t("documents.deleteDocumentTitle") + doc.documentName + "?"} 
                    onAccept={() => onDocumentDelete(documentIdToDelete)} 
                    close={() => setDocumentIdToDelete(null)}/>
    )
  }

  const renderAddWorkLogPopup = () => {
    if (!isAddWorkLogPopupVisible)
      return null;

    return (
      <CustomPopup  label={t("workLogManagement.addWorkLogTitle")} 
                    onAccept={onWorkLogAdd} 
                    isAcceptDisabled={!workLogAddConstructionId || !workLogAddStartDate}
                    customBody={renderAddWorkLogForm()}
                    close={onAddWorkLogPopupClose}/>
    )
  }

  const renderAddDaysOffPopup = () => {
    if (!isAddDaysOffPopupVisible)
      return null;

    return (
      <CustomPopup  label={t("userManagement.addUserDaysOffTitle")} 
                    onAccept={onUserDaysOffAdd} 
                    isAcceptDisabled={!daysOffAddStartDate || !daysOffAddEndDate || !daysOffAddType || !daysOffAddType.value}
                    customBody={renderAddUserDaysOffForm()}
                    close={onAddUserDaysOffPopupClose}/>
    )
  }

  const onWorkLogAdd = () => {
    if (workLogAddConstructionId) {
      const body = {
        constructionId: workLogAddConstructionId.value,
        userId: contextUser.id,
        startDate: moment(workLogAddStartDate).valueOf(),
        endDate: moment(workLogAddEndtDate).valueOf()
      };
      addWorkLogAction(body, contextUser.id).then(onAddWorkLogPopupClose);
    }
  }

  const onUserDaysOffAdd = () => {
    const body = {
      userId: contextUser.id,
      type: daysOffAddType.value,
      startDate: moment(daysOffAddStartDate).format("YYYY-MM-DD"),
      endDate: moment(daysOffAddEndDate).format("YYYY-MM-DD")
    };
    addUserDaysOffAction(body, contextUser.id).then(onAddUserDaysOffPopupClose);
  }

  const renderAddWorkLogForm = () => {
    return (
      <div className="editWorkLogForm">
        <CustomDropdown value={workLogAddConstructionId} 
                        onChange={setWorkLogAddConstructionId} 
                        options={constructionOptions} 
                        label={t("reports.construction")} 
                        className="reportInput"/>
        <CustomDatePicker value={workLogAddStartDate} 
                          onChange={setWorkLogAddStartDate} 
                          label={t("reports.startDate")} 
                          className='reportInput marginRight'/>
        <CustomDatePicker value={workLogAddEndtDate} 
                          onChange={setWorkLogAddEndDate} 
                          label={t("reports.endDate")} 
                          className='reportInput marginRight marginTop'/>
      </div>
    );
  }

  const renderAddUserDaysOffForm = () => {
    return (
      <div className="editWorkLogForm">
        <CustomDropdown value={daysOffAddType} 
                        onChange={setDaysOffAddType} 
                        options={dayOffTypeOptions} 
                        label={t("tableCommons.type")} 
                        className="reportInput"/>
        <CustomDatePicker value={daysOffAddStartDate} 
                          onChange={setDaysOffAddStartDate} 
                          label={t("reports.startDate")} 
                          withoutTime={true}
                          className='reportInput marginRight'/>
        <CustomDatePicker value={daysOffAddEndDate} 
                          onChange={setDaysOffAddEndDate} 
                          label={t("reports.endDate")} 
                          withoutTime={true}
                          className='reportInput marginRight marginTop'/>
      </div>
    );
  }

  const onAddWorkLogPopupClose = () => {
    setIsAddWorkLogPopupVisible(false);
    setWorkLogAddConstructionId(null);
    setWorkLogAddStartDate(null);
    setWorkLogAddEndDate(null);
  }

  const onAddUserDaysOffPopupClose = () => {
    setIsAddDaysOffPopupVisible(false);
    setDaysOffAddStartDate(null);
    setDaysOffAddEndDate(null);
    setDaysOffAddType(null);
  }

  const onQRCodeDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = contextUser.username;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const renderQR = () => {
    if (!contextUser)
      return null;

    return (
      <div className="qrCodeSectionContainer">
        <QRCode id="QRCode" size={256} value={contextUser.id} style={{marginBottom: '20px'}}/>
        <div className="qrText">
          {contextUser.id}
        </div>
        <CustomButton label={t("common.download")} onClick={onQRCodeDownload} style={{width: '195px'}}/>
      </div>
    )
  }

  const renderBottomSection = () => {
    if (!contextUser)
      return null;

    if (user.role === UserRole.SUPER_ADMIN) {
      switch(bottomSection) {
        case SuperAdminBottomSection.CHANGE_PASSWORD:
          return <PasswordChange user={contextUser}/>;
        case SuperAdminBottomSection.EDIT:
        default:
          return renderUserEditForm();
      }
    } else if (user.role === UserRole.TENANT_DIRECTOR) {
      switch(bottomSection) {
        case DirectorBottomSection.WORK_LOG:
          return <WorkLogList contextUser={contextUser}/>;
        case DirectorBottomSection.DOCUMENTS:
            return <DocumentsList documents={documents} 
                                  fetchAction={() => fetchUserDocumentsAction(contextUser.id)}
                                  onFileUpload={onDocumentUpload}
                                  onDocumentDelete={setDocumentIdToDelete}/>;
        case DirectorBottomSection.DAYS_OFF:
          return <UserDaysOffList userId={contextUser.id} />;
        case DirectorBottomSection.QR:
          return renderQR(contextUser);
        case DirectorBottomSection.DEVICES:
        default:
          return <UserDevices contextUser={contextUser} />;
      }
    } else {
      switch(bottomSection) {
        case BottomSection.CHANGE_PASSWORD:
          return <PasswordChange user={contextUser}/>;
        case BottomSection.WORK_LOG:
          return <WorkLogList contextUser={contextUser}/>;
        case BottomSection.DOCUMENTS:
            return <DocumentsList documents={documents} 
                                  fetchAction={() => fetchUserDocumentsAction(contextUser.id)}
                                  onFileUpload={onDocumentUpload}
                                  onDocumentDelete={setDocumentIdToDelete}/>;
        case BottomSection.DAYS_OFF:
          return <UserDaysOffList userId={contextUser.id} />;
        case BottomSection.DEVICES:
          return <UserDevices contextUser={contextUser} />;
        case BottomSection.QR:
          return renderQR(contextUser);
        case BottomSection.EDIT:
        default:
          return renderUserEditForm();
      }
    }
  }

  const renderFileUploadButton = () => {
    return (
      <>
        <CustomButton label={t("common.addDocument")} onClick={() => fileInput.current.click()} style={{marginLeft: "20px"}}/>
        <input  type="file" 
                accept=".png, .jpg, .jpeg, .gif, .doc, .docx, .pdf, .xls, .xlsx, .zip, .rar" 
                ref={fileInput} 
                onChange={onDocumentUpload} 
                style={{display: "none"}}/>
      </>
    );
  }

  const renderAddWorkLogButton = () => {
    if (!constructionOptions || constructionOptions.length === 0)
      return null;

    return (
      <CustomButton label={t("common.addWorkLog")} onClick={() => setIsAddWorkLogPopupVisible(true)} style={{marginLeft: "20px"}}/>
    );
  }

  const renderAddDaysOffButton = () => {
    return (
      <CustomButton label={t("common.addDaysOff")} onClick={() => setIsAddDaysOffPopupVisible(true)} style={{marginLeft: "20px"}}/>
    );
  }

  const getBottomSection = () => {
    if (!user)
      return BottomSection;

    switch (user.role) {
      case UserRole.SUPER_ADMIN:
        return SuperAdminBottomSection;
      case UserRole.TENANT_DIRECTOR:
        return DirectorBottomSection;
      default:
        return BottomSection;
    }
  }

  const renderBottomSectionButtons = () => {
    const bottomSectionByRole = getBottomSection();
    return (
      <div className="bottomStatusButtons">
        {Object.keys(bottomSectionByRole).map(section => (
            <div  className={"statusButton" + (section === bottomSection ? " statusButtonActive" : "")}
                  key={section}
                  onClick={() => setBottomSection(bottomSectionByRole[section])}
          >
            {t("userBottomSections." + section)}
          </div>
        ))}
      </div>
    );
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer spaceBetween">
        <div className="screenTitleContainerRow">
          <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
          { contextUser &&
            <div className="nestedScreenTitle">
              {t("userManagement.editTitle") + contextUser.username}
            </div>
          }
        </div>
        {bottomSection === BottomSection.DOCUMENTS && renderFileUploadButton()}
        {bottomSection === BottomSection.WORK_LOG && renderAddWorkLogButton()}
        {bottomSection === BottomSection.DAYS_OFF && renderAddDaysOffButton()}
      </div>
    )
  }

  

  const renderUserEditForm = () => {
    return (
      <div className="userEditForm">
        <CustomDropdown value={status} 
                        onChange={setStatus} 
                        options={statusOptions} 
                        label={t("tableCommons.statusLabel")} 
                        className="halfWidth" 
                        style={{marginTop: "20px"}}/>
        {(contextUser && contextUser.role !== UserRole.SUPER_ADMIN) &&
          <CustomDropdown value={role} 
                          onChange={setRole} 
                          options={roleOptions} 
                          label={t("common.role")} 
                          className="halfWidth"/>
        }
        <TextInput  value={email} 
                    onChange={setEmail} 
                    label={t("common.email")} 
                    maxLength={255} 
                    className="halfWidth"/>
        <TextInput  value={phoneNumber} 
                    onChange={setPhoneNumber} 
                    label={t("tableCommons.phoneNumberLabel")} 
                    maxLength={255} 
                    className="halfWidth"/>
        <TextInput  value={privatePhoneNumber} 
                    onChange={setPrivatePhoneNumber} 
                    label={t("tableCommons.privatePhoneNumberLabel")} 
                    maxLength={255} 
                    className="halfWidth"/>
        <CustomDropdown value={language} 
                        onChange={setLanguage} 
                        options={languageOptions} 
                        label={t("tableCommons.languageLabel")} 
                        className="halfWidth"/>
        {error && 
          <div className="errorMessage">
            {error}
          </div>
        }
        <div className="formButtons halfWidth">
          <CustomButton label={t("common.save")} onClick={onSubmit}/>
        </div>
      </div>
    );
  }

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      {renderBottomSectionButtons()}
      {renderBottomSection()}
      {renderDeleteDocumentPopup()}
      {renderAddWorkLogPopup()}
      {renderAddDaysOffPopup()}
    </div>
  );
}

export default UserEdit;
