const UserRole = {
    USER: "USER",
    MANAGER: "MANAGER",
    TENANT_ADMIN: "TENANT_ADMIN",
    SUPER_ADMIN: "SUPER_ADMIN",
    WAREHOUSE: "WAREHOUSE",
    TENANT_DIRECTOR: "TENANT_DIRECTOR",
    TERMINAL: "TERMINAL"
}

export default UserRole;