import React, { useEffect, useState } from 'react';
import './FuelTankManagement.css';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../utils/tableUtils';
import moment from 'moment';
import CustomButton from '../../components/CustomButton/CustomButton';
import FuelTankStatus from '../../constants/FuelTankStatus';
import { fetchFuelTanksAction } from '../../redux/actions/fuelTankActions';
import { useNavigate } from "react-router-dom";
import UserRole from '../../constants/UserRole';

function FuelTankManagement() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(FuelTankStatus.ACTIVE);
  const fuelTanks = useSelector((state) => state.fuelTank.fuelTanks);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (selectedStatus)
      fetchFuelTanksAction(selectedStatus);
  }, [selectedStatus]);

  const onRowClick = (event, rowData) => {
    navigate("/fuelTanks/edit/" + rowData.id);
  }

  const mappedFuelTanks = !fuelTanks ? [] : fuelTanks.map(fuelTank => ({
    ...fuelTank,
    status: t("common.status." + fuelTank.status),
    creationDate: moment(fuelTank.creationDate).format("DD/MM/YYYY HH:mm")
  }))

  const renderTitle = () => {
    return (
      <div>
        <div className="screenTitleContainer spaceBetween">
          <div className="screenTitleText">
            {t("sideMenu.fuelTanks")}
          </div>
          { (user && (user.role === UserRole.SUPER_ADMIN || user.role === UserRole.TENANT_ADMIN)) &&
            <CustomButton label={t("common.add")} onClick={() => navigate("/fuelTanks/add")}/>
          }
        </div>
        <div className="statusButtons">
          {Object.keys(FuelTankStatus).map(status => (
            <div  className={"statusButton" + (status === selectedStatus ? " statusButtonActive" : "")}
                  key={status}
                  onClick={() => setSelectedStatus(status)}
            >
              {t("common.status." + status)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="innerScreenContainer">
      <MaterialTable
        icons={tableIcons}
        columns={[
          { 
            title: t("tableCommons.fuelTankNameLabel"), 
            field: 'fuelTankName', 
            customSort: (a, b) => a.fuelTankName.localeCompare(b.fuelTankName, user.language)
          },
          { title: t("tableCommons.litersLabel"), field: 'liters' },
          { title: t("tableCommons.creationDateLabel"), field: 'creationDate' }
        ]}
        data={mappedFuelTanks}
        title={renderTitle()}
        onRowClick={onRowClick}
        options={{
          paging:true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100],
        }}
        localization={{
          body: {
              emptyDataSourceMessage: t("tableCommons.noRecords")
          },
          toolbar: {
            searchPlaceholder: t("common.search")
          },
          pagination: {
            labelRowsSelect: t("tableCommons.rows")
          }
        }}
      />
    </div>
  );
}

export default FuelTankManagement;
