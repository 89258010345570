import store from '../store';
import { getUserDaysOff, addUserDaysOff, deleteUserDayOff } from '../../services/userDayOffService.js';
import { userDayOffSlice } from '../features/userDayOffSlice';
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import i18next from 'i18next';
import ErrorCode from '../../constants/ErrorCode';

export async function fetchUserDaysOffAction(userId, startDate, endDate) {
    store.dispatch(userDayOffSlice.actions.fetchUserDaysOffStart());
    return getUserDaysOff(userId, startDate, endDate).then((userDaysOffFetchResponse) => {
        store.dispatch(userDayOffSlice.actions.fetchUserDaysOffSuccess(userDaysOffFetchResponse.data));
    }).catch((error) => {
        store.dispatch(userDayOffSlice.actions.fetchUserDaysOffFailed());
    });
}

export async function addUserDaysOffAction(body, userId) {
    store.dispatch(userDayOffSlice.actions.fetchUserDaysOffStart());
    return addUserDaysOff(body).then(() => {
        return getUserDaysOff(userId);
    }).then((userDaysOffFetchResponse) => {
        store.dispatch(userDayOffSlice.actions.fetchUserDaysOffSuccess(userDaysOffFetchResponse.data));
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.DAY_OFF_ALREADY_EXISTS) {
            showNotification(NotificationType.DANGER, i18next.t('actions.dayOffAlreadyExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.userDayOffAddError'));
        }
        store.dispatch(userDayOffSlice.actions.fetchUserDaysOffFailed());
    });
}

export async function deleteUserDayOffAction(dayOffId, userId) {
    store.dispatch(userDayOffSlice.actions.fetchUserDaysOffStart());
    return deleteUserDayOff(dayOffId).then(() => {
        return getUserDaysOff(userId);
    }).then((userDaysOffFetchResponse) => {
        store.dispatch(userDayOffSlice.actions.fetchUserDaysOffSuccess(userDaysOffFetchResponse.data));
    }).catch((error) => {
        showNotification(NotificationType.DANGER, i18next.t('actions.userDayOffDeleteError'));
        store.dispatch(userDayOffSlice.actions.fetchUserDaysOffFailed());
    });
}

