import React from 'react';
import './SideNavigation.css';
import { useSelector } from 'react-redux';
import UserRole from '../../constants/UserRole';
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

function SideNavigation() {

  const {t} = useTranslation();
  const user = useSelector((state) => state.auth.user);

  const renderLink = (to, label, icon) => {
    const isActive = window.location.pathname.includes(to);
    return (
      <Link to={to} className={"sideNavigationLinkContainer" + (isActive ? " sideNavigationLinkActive" : "")}>
        <div className="sideNavigationLinkIconWrapper">
          <img src={icon} className={"sideNavigationLinkIcon" + (isActive ? " sideNavigationLinkIconActive" : "")} alt="icon" />
        </div>
        <div className="sideNavigationLink">
          {label}
        </div>
      </Link>
    );
  }

  const renderLinks = () => {
    if (user && user.role === UserRole.SUPER_ADMIN)
      return (
        <>
          {renderLink("/tenants", t("sideMenu.tenants"), "tenants-icon.png")}
          {renderLink("/users", t("sideMenu.users"), "workers-icon.png")}
        </>
      );
    else if (user && user.role === UserRole.TENANT_ADMIN)
      return (
        <>
          {renderLink("/users", t("sideMenu.users"), "workers-icon.png")}
          {renderLink("/constructions", t("sideMenu.constructions"), "construction-icon.png")}
          {renderLink("/devices", t("sideMenu.devices"), "devices-icon.png")}
          {renderLink("/fuelTanks", t("sideMenu.fuelTanks"), "fuel-icon.png")}
          {renderLink("/reports", t("sideMenu.reports"), "reports-icon.png")}
          {renderLink("/calendar", t("sideMenu.calendar"), "calendar-icon.png")}
        </>
      );
    else if (user && user.role === UserRole.TENANT_DIRECTOR)
      return (
        <>
          {renderLink("/users", t("sideMenu.users"), "workers-icon.png")}
          {renderLink("/constructions", t("sideMenu.constructions"), "construction-icon.png")}
          {renderLink("/devices", t("sideMenu.devices"), "devices-icon.png")}
          {renderLink("/fuelTanks", t("sideMenu.fuelTanks"), "fuel-icon.png")}
          {renderLink("/calendar", t("sideMenu.calendar"), "calendar-icon.png")}
        </>
      );
    else
      return null;
  }

  return (
    <div className="sideNavigation">
      {renderLinks()}
    </div>
  );
}

export default SideNavigation;
