import React, {useEffect, useState} from 'react';
import './PasswordChange.css';
import {useTranslation} from "react-i18next";
import { updateUserPasswordAction } from '../../redux/actions/userActions';
import TextInput from '../../components/TextInput/TextInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import { isPasswordValid } from '../../utils/validationUtils';

function PasswordChange({user}) {

  const {t} = useTranslation();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, [password, repeatPassword]);

  const onSubmit = () => {
    if (!isPasswordValid(password)) {
      setError(t('error.passwordFormatError'));
    } else if (password !== repeatPassword) {
      setError(t('error.passwordsDontMatch'));
    } else {
      setError(null);
      updateUserPasswordAction({
        userId: user.id,
        password: password
      }).then(() => {
        setPassword("");
        setRepeatPassword("");
      });
    }
  }

  return (
    <div className="passwordChangeContainer">
      <TextInput  value={password} 
                  onChange={setPassword} 
                  label={t("login.newPassword")} 
                  type="password" 
                  maxLength={255} 
                  className="halfWidth"
                  style={{marginTop: "20px"}}/>
      <TextInput  value={repeatPassword} 
                  onChange={setRepeatPassword} 
                  label={t("login.repeatPassword")} 
                  type="password" 
                  maxLength={255} 
                  className="halfWidth"/>
      {error && <div className="errorMessage">{error}</div>}
      <div className="formButtons halfWidth">
        <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!password || !repeatPassword}/>
      </div>
    </div>
  );
}

export default PasswordChange;
