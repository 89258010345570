// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.superAdminLogin {
  display: flex;
  flex: 1 1;
  height: 100vh;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
}

.loginScreenLogo {
  width: 300px;
  height: 300px;
  margin-top: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/SuperAdminLogin/SuperAdminLogin.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAO;EACP,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".superAdminLogin {\n  display: flex;\n  flex: 1;\n  height: 100vh;\n  overflow-y: scroll;\n  align-items: center;\n  flex-direction: column;\n}\n\n.loginScreenLogo {\n  width: 300px;\n  height: 300px;\n  margin-top: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
