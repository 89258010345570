import store from '../store';
import i18next from "i18next";
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import { userSlice } from '../features/userSlice';
import { addUser, editUser, editUserPassword, getAllUsers, getUserDocuments, getUserDevices, getUserById, addUserDocument, deleteUserDocument } from '../../services/userService';
import ErrorCode from '../../constants/ErrorCode';

export async function fetchUsersAction(tenantId, userStatus) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return getAllUsers(tenantId, userStatus).then((usersFetchResponse) => {
        store.dispatch(userSlice.actions.fetchUsersSuccess(usersFetchResponse.data));
    }).catch((error) => {
        store.dispatch(userSlice.actions.fetchUsersFailed());
    });
}

export async function fetchContextUserAction(userId) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return getUserById(userId).then((userFetchResponse) => {
        store.dispatch(userSlice.actions.fetchContextUserSuccess(userFetchResponse.data));
    }).catch((error) => {
        store.dispatch(userSlice.actions.fetchUsersFailed());
    });
}

export async function clearContextUserAction() {
    store.dispatch(userSlice.actions.fetchUsersStart());
    store.dispatch(userSlice.actions.fetchContextUserSuccess(null));
    store.dispatch(userSlice.actions.fetchUsersFailed());
}

export async function fetchUserDocumentsAction(userId) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return getUserDocuments(userId).then((usersFetchResponse) => {
        store.dispatch(userSlice.actions.fetchUserDocumentsSuccess(usersFetchResponse.data));
    }).catch((error) => {
        store.dispatch(userSlice.actions.fetchUsersFailed());
    });
}

export async function fetchUserDevicesAction(userId) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return getUserDevices(userId).then((usersFetchResponse) => {
        store.dispatch(userSlice.actions.fetchUserDevicesSuccess(usersFetchResponse.data));
    }).catch((error) => {
        store.dispatch(userSlice.actions.fetchUsersFailed());
    });
}

export async function addUserAction(data, callback) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return addUser(data).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.addUserSuccess'));
        if (callback)
            callback();
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        store.dispatch(userSlice.actions.fetchUsersFailed());
        if (errorData && errorData === ErrorCode.USER_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('error.userExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.addUserError'));
        }
    });
}

export async function editUserAction(data) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return editUser(data).then((updatedUser) => {
        store.dispatch(userSlice.actions.fetchContextUserSuccess(updatedUser.data));
        showNotification(NotificationType.SUCCESS, i18next.t('actions.editUserSuccess'));
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        store.dispatch(userSlice.actions.fetchUsersFailed());
        if (errorData && errorData === ErrorCode.USER_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('error.userExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.editUserError'));
        }
    });
}

export async function updateUserPasswordAction(data) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return editUserPassword(data).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.editUserSuccess'));
        store.dispatch(userSlice.actions.fetchUsersVoidSuccess());
    }).catch((error) => {
        store.dispatch(userSlice.actions.fetchUsersFailed());
        showNotification(NotificationType.DANGER, i18next.t('actions.editUserError'));
    });
}

export async function addUserDocumentAction(data, callback) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return addUserDocument(data).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.addDocumentSuccess'));
        if (callback)
            callback();
    }).catch((error) => {
        store.dispatch(userSlice.actions.fetchUsersFailed());
        showNotification(NotificationType.DANGER, i18next.t('actions.addDocumentError'));
    });
}

export async function deleteUserDocumentAction(userDocumentId, callback) {
    store.dispatch(userSlice.actions.fetchUsersStart());
    return deleteUserDocument(userDocumentId).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.removeDocumentSuccess'));
        if (callback)
            callback();
    }).catch((error) => {
        store.dispatch(userSlice.actions.fetchUsersFailed());
        showNotification(NotificationType.DANGER, i18next.t('actions.removeDocumentError'));
    });
}