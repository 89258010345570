import axiosService from './axiosService';

export const getAllFuelTanks = (status) => {
  return axiosService.get("/fuelTank?status=" + status);
}

export const getFuelTankById = (fuelTankId) => {
  return axiosService.get("/fuelTank/" + fuelTankId);
}

export const addFuelTank = (body) => {
  return axiosService.post("/fuelTank", body);
}

export const editFuelTank = (body) => {
  return axiosService.put("/fuelTank", body);
}