import store from '../store';
import { addTenant, editTenant, getAllTenants, getTenantById } from '../../services/tenantService';
import { tenantSlice } from '../features/tenantSlice';
import i18next from "i18next";
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import ErrorCode from '../../constants/ErrorCode';

export async function fetchTenantsAction(status) {
    store.dispatch(tenantSlice.actions.fetchTenantsStart());
    return getAllTenants(status).then((tenantsFetchResponse) => {
        store.dispatch(tenantSlice.actions.fetchTenantsSuccess(tenantsFetchResponse.data));
    }).catch((error) => {
        store.dispatch(tenantSlice.actions.fetchTenantsFailed());
    });
}

export async function fetchContextTenantAction(tenantId) {
    store.dispatch(tenantSlice.actions.fetchTenantsStart());
    return getTenantById(tenantId).then((tenantFetchResponse) => {
        store.dispatch(tenantSlice.actions.fetchContextTenantSuccess(tenantFetchResponse.data));
    }).catch((error) => {
        store.dispatch(tenantSlice.actions.fetchTenantsFailed());
    });
}

export async function clearContextTenantAction() {
    store.dispatch(tenantSlice.actions.fetchTenantsStart());
    store.dispatch(tenantSlice.actions.fetchContextTenantSuccess(null));
    store.dispatch(tenantSlice.actions.fetchTenantsFailed());
}

export async function addTenantAction(data, callback) {
    store.dispatch(tenantSlice.actions.fetchTenantsStart());
    return addTenant(data).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.addTenantSuccess'));
        if (callback)
            callback();
    }).catch((error) => {
        const errorData = error && error.response && error.response.data;
        store.dispatch(tenantSlice.actions.fetchTenantsFailed());
        if (errorData && errorData === ErrorCode.TENANT_NAME_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('error.tenantExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.addTenantError'));
        }
    });
}

export async function editTenantAction(data) {
    store.dispatch(tenantSlice.actions.fetchTenantsStart());
    return editTenant(data).then((updatedTenant) => {
        store.dispatch(tenantSlice.actions.fetchContextTenantSuccess(updatedTenant.data));
        showNotification(NotificationType.SUCCESS, i18next.t('actions.editTenantSuccess'));
    }).catch((error) => {
        store.dispatch(tenantSlice.actions.fetchTenantsFailed());
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.TENANT_NAME_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('error.tenantExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.editTenantError'));
        }
    });
}

