import React, { useState, useEffect } from 'react';
import './UserManagement.css';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../utils/tableUtils';
import CustomButton from '../../components/CustomButton/CustomButton';
import { fetchUsersAction } from '../../redux/actions/userActions';
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import UserRole from '../../constants/UserRole';
import { fetchTenantsAction } from '../../redux/actions/tenantActions';
import UserStatus from '../../constants/UserStatus';
import TenantStatus from '../../constants/TenantStatus';
import CustomUserRole from '../../components/CustomUserRole/CustomUserRole';

function UserManagement() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTenantId = searchParams.get('tenantId');
  const [selectedStatus, setSelectedStatus] = useState(UserStatus.ACTIVE);
  const user = useSelector((state) => state.auth.user);
  const users = useSelector((state) => state.user.users);
  const tenants = useSelector((state) => state.tenant.tenants);
  const availableTenants = user.tenant ? [user.tenant] : tenants;
  const tenantOptions = availableTenants.map(tenant => ({value: tenant.id, label: tenant.tenantName}));
  const [selectedTenant, setSelectedTenant] = useState(tenantOptions[0]);

  useEffect(() => { 
    if (user && user.role === UserRole.SUPER_ADMIN)
      fetchTenantsAction(TenantStatus.ACTIVE);
  }, []);

  useEffect(() => { 
    if (availableTenants && availableTenants.length > 0) {
      const selected = selectedTenantId ? tenantOptions.find(t => t.value === selectedTenantId) : tenantOptions[0];
      if (selected && selected.value) {
        setSelectedTenant(selected);
        fetchUsersAction(selected.value, selectedStatus);
      }
    }
  }, [tenants, selectedTenantId]);

  const changeSelectedTenant = (option) => {
    navigate({
      search: '?tenantId=' + option.value,
    });
    setSelectedTenant(option);
    fetchUsersAction(option.value, selectedStatus);
  }

  const onRowClick = (event, rowData) => {
    navigate("/users/edit/" + rowData.id);
  }

  const mappedUsers = !users ? [] : users.map(user => ({
    ...user,
    creationDate: moment(user.creationDate).format("DD/MM/YYYY HH:mm")
  }))

  const onStatusChange = (status) => {
    setSelectedStatus(status);
    fetchUsersAction(selectedTenant.value, status);
  }

  const renderTitle = () => {
    return (
      <>
        <div className="screenTitleContainer spaceBetween">
          <div className="screenTitleText">
            {t("sideMenu.users")}
          </div>
          { (user && (user.role === UserRole.SUPER_ADMIN || user.role === UserRole.TENANT_ADMIN)) &&
            <CustomButton label={t("common.add")} onClick={() => navigate("/users/add/" + selectedTenant.value)}/>
          }
        </div>
        <div className="statusButtons">
          {Object.keys(UserStatus).map(status => (
            <div  className={"statusButton" + (status === selectedStatus ? " statusButtonActive" : "")}
                  key={status}
                  onClick={() => onStatusChange(status)}
            >
              {t("common.status." + status)}
            </div>
          ))}
        </div>
      </>
    );
  }

  const renderUserName = (rowData) => {
    const avatar = rowData.avatar ? rowData.avatar : "/blankAvatar.png";
    return (
      <div className = "userInformationLabel">
        <img src={avatar} alt="avatar" className="userInformationAvatar"/>
        <div className="userInformationLabelText">
          {rowData.username}
        </div>
      </div>
    );
  }

  return (
    <div className="innerScreenContainer">
      {user.role === UserRole.SUPER_ADMIN &&
        <CustomDropdown 
          value={selectedTenant} 
          onChange={changeSelectedTenant} 
          options={tenantOptions} 
          label={t("common.tenant")}
          style={{width: "320px",marginTop: "30px"}}
        />
      }
      <MaterialTable
        icons={tableIcons}
        columns={[
          { 
            title: t("common.username"), 
            field: 'username', render: renderUserName,
            customSort: (a, b) => a.username.localeCompare(b.username, user.language)
          },
          { 
            title: t("common.role"), 
            render: (rowData) => <CustomUserRole role={rowData.role} />,  
            customFilterAndSearch: (term, rowData) => t("common.userRole." + rowData.role).toLowerCase().indexOf(term.toLowerCase()) !== -1,
            customSort: (a, b) => t("common.userRole." + a.role).localeCompare(t("common.userRole." + b.role), user.language)
          },
          { 
            title: t("common.email"), 
            field: 'email',
            customSort: (a, b) => a.email.localeCompare(b.email, user.language) 
          },
          { 
            title: t("tableCommons.phoneNumberLabel"), 
            field: 'phoneNumber',
            customSort: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber, user.language) 
          },
          { 
            title: t("tableCommons.privatePhoneNumberLabel"), 
            field: 'privatePhoneNumber',
            customSort: (a, b) => a.privatePhoneNumber.localeCompare(b.privatePhoneNumber, user.language) 
          },
          { title: t("tableCommons.creationDateLabel"), field: 'creationDate' }
        ]}
        data={mappedUsers}
        title={renderTitle()}
        onRowClick={onRowClick}
        options={{
          paging:true,
          pageSize: 100,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100],
        }}
        localization={{
          body: {
              emptyDataSourceMessage: t("tableCommons.noRecords")
          },
          toolbar: {
            searchPlaceholder: t("common.search")
          },
          pagination: {
            labelRowsSelect: t("tableCommons.rows")
          }
        }}
      />
    </div>
  );
}

export default UserManagement;
