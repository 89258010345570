import React, { useState, useEffect, useRef } from 'react';
import './DeviceEdit.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import { 
  clearContextDeviceAction, 
  editDeviceAction, 
  fetchContextDeviceAction, 
  fetchDeviceDocumentsAction, 
  assignDeviceAction, 
  addDeviceDocumentAction,
  deleteDeviceDocumentAction
} from '../../../redux/actions/deviceActions';
import DeviceStatus from '../../../constants/DeviceStatus';
import TextArea from '../../../components/TextArea/TextArea';
import PowerType from '../../../constants/PowerType';
import DeviceCondition from '../../../constants/DeviceCondition';
import DocumentsList from '../../DocumentsList/DocumentsList';
import { useSelector } from 'react-redux';
import UserSelect from '../../../components/UserSelect/UserSelect';
import { useParams, useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from 'react-icons/md';
import AssignedUser from '../../../components/AssignedUser/AssignedUser';
import { uploadFile } from '../../../utils/firebaseUtils';
import CustomPopup from '../../../components/CustomPopup/CustomPopup';
import { isNumberValid } from '../../../utils/validationUtils';
import CustomReadOnly from '../../../components/CustomReadOnly/CustomReadOnly';
import moment from 'moment';
import DeviceHistoryList from './DeviceHistoryList';

const Section = {
  EDIT: "EDIT",
  DOCUMENTS: "DOCUMENTS",
  HISTORY: "HISTORY"
}

function DeviceEdit() {

  const {t} = useTranslation();
  const fileInput = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const deviceId = params.deviceId;
  const contextDevice = useSelector((state) => state.device.contextDevice);
  const deviceConditionOptions = Object.keys(DeviceCondition).map(key => ({value: key, label: t("common.deviceCondition." + key)}));
  const powerTypeOptions = Object.keys(PowerType).map(key => ({value: key, label: t("common.powerType." + key)}));
  const statusOptions = Object.keys(DeviceStatus).map(key => ({value: key, label: t("common.status." + key)}));

  const [deviceName, setDeviceName] = useState("");
  const [deviceNumber, setDeviceNumber] = useState("");
  const [deviceDescription, setDeviceDescription] = useState("");
  const [status, setStatus] = useState(null);
  const [deviceCondition, setDeviceCondition] = useState(null);
  const [powerType, setPowerType] = useState(null);
  const [section, setSection] = useState(Section.EDIT);
  const [documentIdToDelete, setDocumentIdToDelete] = useState(null);
  const [deviceMileage, setDeviceMileage] = useState("");
  const [error, setError] = useState(null);

  const documents = useSelector((state) => state.device.deviceDocuments);

  useEffect(() => {
    return clearContextDeviceAction;
  }, []);

  useEffect(() => { 
    if (deviceId && !contextDevice)
      fetchContextDeviceAction(deviceId);
    
    if (contextDevice) {
      setDeviceName(contextDevice.deviceName);
      setDeviceNumber(contextDevice.deviceNumber);
      setDeviceDescription(contextDevice.deviceDescription);
      setStatus(statusOptions.find(s => s.value === DeviceStatus[contextDevice.status]));
      setDeviceCondition( deviceConditionOptions.find(d => d.value === contextDevice.deviceCondition));
      setPowerType(powerTypeOptions.find(pt => pt.value === contextDevice.powerType));
      setDeviceMileage(contextDevice.mileage ? contextDevice.mileage : '');
    }

  }, [contextDevice, deviceId]);

  const onSubmit = () => {
    if (deviceMileage && !isNumberValid(deviceMileage)) {
      setError(t('error.deviceMileageFormat'));
    } else {
      setError(null);
      editDeviceAction({
        id: contextDevice.id, 
        deviceName, 
        deviceNumber,
        deviceDescription,
        deviceCondition: deviceCondition.value,
        powerType: powerType.value,
        status: status.value,
        mileage: deviceMileage,
      });
    }
  }

  const onAssigneeChange = (assigneeId) => {
    assignDeviceAction({
      userId: assigneeId,
      deviceId: contextDevice.id
    });
  }

  const onDocumentUpload = (e) => {
    const file = e.target.files[0];
    uploadFile(file.name, file).then(response => {
      return addDeviceDocumentAction({
        deviceId: contextDevice.id,
        documentName: file.name,
        documentContent: response
      }, () => fetchDeviceDocumentsAction(contextDevice.id));
    }).catch(e => {
      console.log("Upload file error: " + e);
    });
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer spaceBetween">
        <div className="screenTitleContainerRow">
          <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
          { contextDevice &&
            <div className="nestedScreenTitle">
              {t("deviceManagement.editTitle") + contextDevice.deviceName}
            </div>
          }
        </div>
        {section === Section.DOCUMENTS && renderFileUploadButton()}
      </div>
    )
  }

  const renderSectionButtons = () => {
    return (
      <div className="bottomStatusButtons" >
        {Object.keys(Section).map(s => (
          <div  className={"statusButton" + (s === section ? " statusButtonActive" : "")}
                key={s}
                onClick={() => setSection(Section[s])}
          >
            {t("userBottomSections." + s)}
          </div>
        ))}
      </div>
    );
  }

  const onDocumentDelete = (deviceDocumentId) => {
    deleteDeviceDocumentAction(deviceDocumentId, () => {
      fetchDeviceDocumentsAction(contextDevice.id);
      setDocumentIdToDelete(null);
    });
  }

  const renderDeleteDocumentPopup = () => {
    if (!documentIdToDelete)
      return null;

    const doc = documents.find(d => d.id === documentIdToDelete);
    return (
      <CustomPopup  label={t("documents.deleteDocumentTitle") + doc.documentName + "?"} 
                    onAccept={() => onDocumentDelete(documentIdToDelete)} 
                    close={() => setDocumentIdToDelete(null)}/>
    )
  }

  const renderEditForm = () => {
    return (
      <div className="deviceEditForm">
        <TextInput  value={deviceName} 
                    onChange={setDeviceName} 
                    label={t("tableCommons.deviceNameLabel")} 
                    className="halfWidth"
                    style={{marginTop: "20px"}}
                    maxLength={255} />
        <TextInput  value={deviceNumber} 
                    onChange={setDeviceNumber} 
                    label={t("tableCommons.deviceNumberLabel")} 
                    className="halfWidth"
                    maxLength={60} />
        <TextArea value={deviceDescription} 
                  onChange={setDeviceDescription} 
                  label={t("tableCommons.deviceDescriptionLabel")} 
                  className="halfWidth"
                  maxLength={1000} />
        <CustomDropdown value={status} 
                        onChange={setStatus} 
                        options={statusOptions} 
                        label={t("tableCommons.statusLabel")} 
                        className="halfWidth"/>
        <CustomDropdown value={deviceCondition} 
                        onChange={setDeviceCondition} 
                        options={deviceConditionOptions} 
                        label={t("tableCommons.deviceConditionLabel")} 
                        className="halfWidth"/>
        <CustomDropdown value={powerType} 
                        onChange={setPowerType} 
                        options={powerTypeOptions} 
                        label={t("tableCommons.powerTypeLabel")} 
                        className="halfWidth"/>
        {powerType && powerType.value === PowerType.FUEL &&
          <TextInput  value={deviceMileage} 
                      onChange={setDeviceMileage} 
                      label={t("tableCommons.deviceMileage")} 
                      className="halfWidth"
                      maxLength={60} />
        }
        <div className="assignedUserRow halfWidth">
          <AssignedUser user={contextDevice.assignee} label={t("tableCommons.assignedToLabel")}/>
          <UserSelect label={t("tableCommons.assigneeLabel")} onChange={onAssigneeChange}/>
        </div>
        {contextDevice.lastAssignedOn &&
          <CustomReadOnly label={t("tableCommons.lastAssignedOn")} 
                          value={moment(contextDevice.lastAssignedOn).format("DD/MM/YYYY HH:mm")}
                          className="halfWidth"/>
        }
        <CustomReadOnly label={t("tableCommons.creationDateLabel")} 
                        value={moment(contextDevice.creationDate).format("DD/MM/YYYY HH:mm")}
                        className="halfWidth"/>
        {error && 
          <div className="errorMessage">
            {error}
          </div>
        }
        <div className="formButtons halfWidth">
          <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!deviceName || !deviceNumber}/>
        </div>
      </div>
    );
  }

  const renderFileUploadButton = () => {
    return (
      <>
        <CustomButton label={t("common.addDocument")} onClick={() => fileInput.current.click()} style={{marginLeft: "20px"}}/>
        <input  type="file" 
                accept=".png, .jpg, .jpeg, .gif, .doc, .docx, .pdf, .xls, .xlsx, .zip, .rar" 
                ref={fileInput} 
                onChange={onDocumentUpload} 
                style={{display: "none"}}/>
      </>
    );
  }

  const renderSection = () => {
    if (!contextDevice)
      return null;

    switch(section) {
      case Section.DOCUMENTS:
          return <DocumentsList   documents={documents} 
                                  fetchAction={() => fetchDeviceDocumentsAction(contextDevice.id)}
                                  onFileUpload={onDocumentUpload}
                                  onDocumentDelete={setDocumentIdToDelete}/>
      case Section.HISTORY:
          return <DeviceHistoryList deviceId={contextDevice.id} />
      case Section.EDIT:
      default:
        return renderEditForm();
    }
  }

  if (!contextDevice)
    return null;

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      {renderSectionButtons()}
      {renderSection()}
      {renderDeleteDocumentPopup()}
    </div>
  );
}

export default DeviceEdit;
