import store from '../store';
import { 
    addDevice, 
    addDeviceDocument, 
    assignDevice, 
    deleteDeviceDocument, 
    editDevice, 
    getAllDevices, 
    getDeviceById, 
    getDeviceDocuments,
    exportDevices,
    importDevices,
    getDeviceHistory
} from '../../services/deviceService';
import { deviceSlice } from '../features/deviceSlice';
import i18next from "i18next";
import { NotificationType, showNotification } from '../../utils/notificationUtils';
import ErrorCode from '../../constants/ErrorCode';
import moment from 'moment';

export async function fetchDevicesAction(status, page, rowsPerPage, sortBy, sortDirection, search) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return getAllDevices(status, page, rowsPerPage, sortBy, sortDirection, search).then((devicesFetchResponse,) => {
        store.dispatch(deviceSlice.actions.fetchDevicesSuccess(devicesFetchResponse.data));
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
    });
}

export async function fetchContextDeviceAction(deviceId) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return getDeviceById(deviceId).then((deviceFetchResponse) => {
        store.dispatch(deviceSlice.actions.fetchContextDeviceSuccess(deviceFetchResponse.data));
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
    });
}

export async function clearContextDeviceAction() {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    store.dispatch(deviceSlice.actions.fetchContextDeviceSuccess(null));
    store.dispatch(deviceSlice.actions.fetchDevicesFailed());
}

export async function fetchDeviceDocumentsAction(deviceId) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return getDeviceDocuments(deviceId).then((deviceDocumentsFetchResponse) => {
        store.dispatch(deviceSlice.actions.fetchDeviceDocumentsSuccess(deviceDocumentsFetchResponse.data));
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
    });
}

export async function fetchDeviceHistoryAction(deviceId) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return getDeviceHistory(deviceId).then((deviceHistoryFetchResponse) => {
        store.dispatch(deviceSlice.actions.fetchDeviceHistorySuccess(deviceHistoryFetchResponse.data));
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
    });
}

export async function addDeviceAction(data, callback) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return addDevice(data).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.addDeviceSuccess'));
        if (callback)
            callback();
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.DEVICE_NUMBER_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('actions.deviceNumberAlreadyExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.addDeviceError'));
        }
    });
}

export async function editDeviceAction(data) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return editDevice(data).then((updatedDevice) => {
        store.dispatch(deviceSlice.actions.fetchContextDeviceSuccess(updatedDevice.data));
        showNotification(NotificationType.SUCCESS, i18next.t('actions.editDeviceSuccess'));
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.DEVICE_NUMBER_ALREADY_EXISTS) {
            showNotification(NotificationType.WARNING, i18next.t('actions.deviceNumberAlreadyExists'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.editDeviceError'));
        }
    });
}

export async function assignDeviceAction(data) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return assignDevice(data).then((updatedDevice) => {
        store.dispatch(deviceSlice.actions.fetchContextDeviceSuccess(updatedDevice.data));
        showNotification(NotificationType.SUCCESS, i18next.t('actions.deviceAssignmentSuccess'));
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
        showNotification(NotificationType.DANGER, i18next.t('actions.deviceAssignmentError'));
    });
}

export async function addDeviceDocumentAction(data, callback) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return addDeviceDocument(data).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.addDocumentSuccess'));
        store.dispatch(deviceSlice.actions.fetchDevicesVoidSuccess());
        if (callback)
            callback();
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
        showNotification(NotificationType.DANGER, i18next.t('actions.addDocumentError'));
    });
}

export async function deleteDeviceDocumentAction(deviceDocumentId, callback) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return deleteDeviceDocument(deviceDocumentId).then(() => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.removeDocumentSuccess'));
        store.dispatch(deviceSlice.actions.fetchDevicesVoidSuccess());
        if (callback)
            callback();
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
        showNotification(NotificationType.DANGER, i18next.t('actions.removeDocumentError'));
    });
}

export async function exportDevicesAction() {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return exportDevices().then((response) => {
        store.dispatch(deviceSlice.actions.fetchDevicesVoidSuccess());
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'devices-export-' + moment().valueOf() + '.csv');
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
    });
}

export async function importDevicesAction(file) {
    store.dispatch(deviceSlice.actions.fetchDevicesStart());
    return importDevices(file).then((response) => {
        showNotification(NotificationType.SUCCESS, i18next.t('actions.importDevicesSuccess') + response.data);
        store.dispatch(deviceSlice.actions.fetchDevicesVoidSuccess());
    }).catch((error) => {
        store.dispatch(deviceSlice.actions.fetchDevicesFailed());
        const errorData = error && error.response && error.response.data;
        if (errorData && errorData === ErrorCode.FILE_ERROR) {
            showNotification(NotificationType.WARNING, i18next.t('actions.importDeviceFileError'));
        } else {
            showNotification(NotificationType.DANGER, i18next.t('actions.importDeviceError'));
        }
    });
}
