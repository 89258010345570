import React, {useState} from 'react';
import '../Reports.css';
import {useTranslation} from "react-i18next";
import UserSelect from '../../../components/UserSelect/UserSelect';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { generateUserWorkMonthReportAction } from '../../../redux/actions/reportActions';
import { getMonthOptions, getYearOptions } from '../../../utils/dateUtils';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import moment from 'moment';

function UserWorkMonthReport() {
  const {t} = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);

  const onSubmit = (format) => {
    generateUserWorkMonthReportAction({
      userId: selectedUserId,
      year: year.value,
      month: month.value,
      zoneOffset: moment().format('Z'),
      format: format
    });
  }

  const renderTitle = () => {
    return (
      <div className="reportTitleContainer">
        <div className="reportTitleText">
          {t("reports.userWorkMonthReportTitle")}
        </div>    
      </div>
    )
  }

  return (
    <div className="reportContainer">
      {renderTitle()}
      <div className="reportDescription">
        {t("reports.userWorkMonthReportDescription")}
      </div>
      <div className="reportForm">
        <div className="reportFormRow">
          <UserSelect label={t("common.user")} 
                      value={selectedUserId} 
                      onChange={setSelectedUserId} 
                      className='reportInput'/>
          <CustomDropdown value={month} 
                          onChange={setMonth} 
                          options={getMonthOptions()} 
                          label={t("tableCommons.monthLabel")} 
                          className="reportInput"/>
          <CustomDropdown value={year} 
                          style={{marginLeft: 0}}
                          onChange={setYear} 
                          options={getYearOptions()} 
                          label={t("tableCommons.yearLabel")} 
                          className="reportInput"/>
        </div>
        <div className="reportFormRow">
          <CustomButton label={"CSV"} style={{marginRight: "10px"}} onClick={() => onSubmit("CSV")} isDisabled={!selectedUserId || !year || !month}/>
          <CustomButton label={"XLSX"} onClick={() => onSubmit("XLSX")} isDisabled={!selectedUserId || !year || !month}/>
        </div>
      </div>
    </div>
  );
}

export default UserWorkMonthReport;
