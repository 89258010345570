import React, { useState } from 'react';
import './ConstructionAdd.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { addConstructionAction } from '../../../redux/actions/constructionActions';
import { useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from 'react-icons/md';

function ConstructionAdd() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [constructionName, setConstructionName] = useState("");
  const [locationLatitude, setLocationLatitude] = useState("");
  const [locationLongitude, setLocationLongitude] = useState("");

  const onSubmit = () => {
    addConstructionAction({
      constructionName, 
      locationLatitude,
      locationLongitude
    }, () => navigate("/constructions"));
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer">
        <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
        <div className="nestedScreenTitle">
          {t("constructionManagement.addTitle")}
        </div>
      </div>
    )
  }

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      <TextInput  value={constructionName} 
                  onChange={setConstructionName} 
                  label={t("tableCommons.constructionNameLabel")} 
                  maxLength={255} 
                  className="halfWidth"/>
      <TextInput  value={locationLatitude} 
                  onChange={setLocationLatitude} 
                  label={t("tableCommons.locationLatitudeLabel")} 
                  maxLength={60} 
                  className="halfWidth"/>
      <TextInput  value={locationLongitude} 
                  onChange={setLocationLongitude} 
                  label={t("tableCommons.locationLongitudeLabel")} 
                  maxLength={60} 
                  className="halfWidth"/>
      <div className="formButtons halfWidth">
        <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!constructionName}/>
      </div>
    </div>
  );
}

export default ConstructionAdd;
