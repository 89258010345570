import axiosService from './axiosService';

export const getAllConstructions = (status) => {
  return axiosService.get("/construction?status=" + status);
}

export const getConstructionById = (constructionId) => {
  return axiosService.get("/construction/" + constructionId);
}

export const addConstruction = (body) => {
  return axiosService.post("/construction", body);
}

export const editConstruction = (body) => {
  return axiosService.put("/construction", body);
}