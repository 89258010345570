import React from 'react';
import './FetchingIndicator.css';
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from 'react-redux';

function FetchingIndicator() {

  const isAuthLoading = useSelector((state) => state.auth.isLoading);
  const isUserLoading = useSelector((state) => state.user.isLoading);
  const isDeviceLoading = useSelector((state) => state.device.isLoading);
  const isConstructionLoading = useSelector((state) => state.construction.isLoading);
  const isFuelLogLoading = useSelector((state) => state.fuelLog.isLoading);
  const isFuelTankLoading = useSelector((state) => state.fuelTank.isLoading);
  const isReportLoading = useSelector((state) => state.report.isLoading);
  const isTenantLoading = useSelector((state) => state.tenant.isLoading);
  const isWorkLogLoading = useSelector((state) => state.workLog.isLoading);
  const isCalendarLoading = useSelector((state) => state.calendar.isLoading);
  const isFetching = 
    isAuthLoading || 
    isUserLoading ||
    isDeviceLoading ||
    isConstructionLoading ||
    isFuelLogLoading ||
    isFuelTankLoading ||
    isReportLoading ||
    isTenantLoading ||
    isWorkLogLoading ||
    isCalendarLoading;

  if (!isFetching)
    return null;

  return (
    <div className="fetchingIndicator">
      <ClipLoader loading={true} size={150} />
    </div>
  );
}

export default FetchingIndicator;
