import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    user: null
  },
  reducers: {
    loginStart: (state) => {
        state.isLoading = true;
    },
    loginSuccess: (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
    },
    loginFailed: (state) => {
        state.isLoading = false;
    },
    logout: (state) => {
      state.isLoading = true;
      state.user = null;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
    }
  },
})

export const { 
  loginStart, 
  loginSuccess, 
  loginFailed, 
  logout,
  logoutSuccess
} = authSlice.actions

export default authSlice.reducer