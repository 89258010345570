import React, { useState, useEffect } from 'react';
import './TenantEdit.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Language from '../../../constants/Language';
import TenantStatus from '../../../constants/TenantStatus';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import { clearContextTenantAction, editTenantAction, fetchContextTenantAction } from '../../../redux/actions/tenantActions';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { MdKeyboardBackspace } from 'react-icons/md';

function TenantEdit() {

  const {t} = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const tenantId = params.tenantId;
  const contextTenant = useSelector((state) => state.tenant.contextTenant);

  const languageOptions = Object.keys(Language).map(key => ({value: key, label: t("common.language." + key)}));
  const statusOptions = Object.keys(TenantStatus).map(key => ({value: key, label: t("common.status." + key)}));

  const [tenantName, setTenantName] = useState("");
  const [status, setStatus] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(null);

  useEffect(() => { 
    return clearContextTenantAction;
  }, []);

  useEffect(() => { 
    if (tenantId && !contextTenant)
      fetchContextTenantAction(tenantId);
    
    if (contextTenant) {
      setTenantName(contextTenant.tenantName);
      setStatus(statusOptions.find(s => s.value === TenantStatus[contextTenant.status]));
      setDefaultLanguage(languageOptions.find(l => l.value === Language[contextTenant.defaultLanguage]));    
    }

  }, [contextTenant, tenantId]);
  
  const onSubmit = () => {
    editTenantAction({
      id: contextTenant.id, 
      tenantName, 
      status: status.value, 
      defaultLanguage: defaultLanguage.value
    });
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer">
        <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
        { contextTenant &&
          <div className="nestedScreenTitle">
            {t("tenantManagement.editTitle") + contextTenant.tenantName}
          </div>
        }
      </div>
    )
  }

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      <TextInput  value={tenantName} 
                  onChange={setTenantName} 
                  label={t("tableCommons.tenantNameLabel")} 
                  className="halfWidth"
                  maxLength={255} />
      <CustomDropdown value={status} 
                      onChange={setStatus} 
                      options={statusOptions} 
                      label={t("tableCommons.statusLabel")} 
                      className="halfWidth"/>
      <CustomDropdown value={defaultLanguage} 
                      onChange={setDefaultLanguage} 
                      options={languageOptions} 
                      label={t("tableCommons.defaultLanguageLabel")} 
                      className="halfWidth" />
      <div className="formButtons halfWidth" >
        <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!tenantName}/>
      </div>
    </div>
  );
}

export default TenantEdit;
