import React, { useState } from 'react';
import TextInput from '../../components/TextInput/TextInput';
import './Login.css';
import {useTranslation} from "react-i18next";
import CustomButton from '../../components/CustomButton/CustomButton';
import { loginUserAction } from '../../redux/actions/authActions';

function Login() {

  const {t} = useTranslation();
  const [tenantName, setTenantName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const onSubmit = () => {
    loginUserAction(tenantName, username, password);
  }

  const isDisabled = !tenantName || !username || !password;

  return (
    <div className="login">
      <img src={"logoBig.png"} className="loginScreenLogo" alt="logo" />
      <TextInput value={tenantName} onChange={setTenantName} label={t("login.organization")}/>
      <TextInput value={username} onChange={setUsername} label={t("common.username")}/>
      <TextInput value={password} onChange={setPassword} label={t("login.password")} type="password" onEnter={onSubmit}/>
      <CustomButton label={t("login.submit")} onClick={onSubmit} isDisabled={isDisabled} style={{marginBottom: "20px"}}/>
    </div>
  );
}

export default Login;
