import React, { useEffect, useRef, useState } from 'react';
import './DeviceManagement.css';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../utils/tableUtils';
import moment from 'moment';
import CustomButton from '../../components/CustomButton/CustomButton';
import DeviceStatus from '../../constants/DeviceStatus';
import DeviceCondition from '../../constants/DeviceCondition';
import { exportDevicesAction, fetchDevicesAction, importDevicesAction } from '../../redux/actions/deviceActions';
import { useNavigate, useSearchParams } from "react-router-dom";

function DeviceManagement() {
  const fileInput = useRef(null);
  const tableRef = useRef(null);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const devices = useSelector((state) => state.device.devices);
  const user = useSelector((state) => state.auth.user);

  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.get("selectedStatus") && searchParams.get("selectedStatus") !== 'null' 
      ? searchParams.get("selectedStatus") 
      : DeviceStatus.ACTIVE
  );
  const [page, setPage] = useState(
    searchParams.get("page") && searchParams.get("page") !== 'null' 
      ? parseInt(searchParams.get("page")) 
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    searchParams.get("rowsPerPage") && searchParams.get("rowsPerPage") !== 'null' 
      ? parseInt(searchParams.get("rowsPerPage")) 
      : 20
  );
  const [sortBy, setSortBy] = useState(
    searchParams.get("sortBy") && searchParams.get("sortBy") !== 'null' 
      ? searchParams.get("sortBy") 
      : null
  );
  const [sortDirection, setSortDirection] = useState(
    searchParams.get("sortDirection") && searchParams.get("sortDirection") !== 'null' 
      ? searchParams.get("sortDirection") 
      : null
  );
  const [search, setSearch] = useState(
    searchParams.get("search") && searchParams.get("search") !== 'null' 
      ? searchParams.get("search") 
      : null
  );

  useEffect(() => {
    setSearchParams({
      selectedStatus: selectedStatus, 
      page: page, 
      rowsPerPage: rowsPerPage, 
      sortBy: sortBy, 
      sortDirection: sortDirection, 
      search: search
    });

    if (selectedStatus)
      fetchDevicesAction(selectedStatus, page, rowsPerPage, sortBy, sortDirection, search);
  }, [selectedStatus, page, rowsPerPage, sortBy, sortDirection, search]);

  const onRowClick = (event, rowData) => {
    navigate("/devices/edit/" + rowData.id);
  }

  const mappedDevices = (!devices || !devices.content) ? [] : devices.content.map(device => ({
    ...device,
    status: t("common.status." + device.status),
    rawDeviceCondition: device.deviceCondition,
    rawPowerType: device.powerType,
    deviceCondition: t("common.deviceCondition." + device.deviceCondition),
    powerType: t("common.powerType." + device.powerType),
    creationDate: moment(device.creationDate).format("DD/MM/YYYY HH:mm")
  }))

  const onImportFileUpload = (e) => {
    const file = e.target.files[0];
    importDevicesAction(file).then(() => {
      if (selectedStatus)
        fetchDevicesAction(selectedStatus, page, rowsPerPage, sortBy, sortDirection, search);
    });
  }

  const onOrderChange = (fieldIndex, direction) => {
    switch(fieldIndex) {
      case 0:
        setSortBy("deviceName");
        break;
      case 1:
        setSortBy("deviceNumber");
        break;
      case 2:
        break;
      case 3:
        setSortBy("powerType");
        break;
      case 4:
        setSortBy("deviceCondition");
        break;
      case -1:
      default:
        setSortBy(null);
        break;
    }
    setSortDirection(direction);
  }

  const onSearchChange = (param) => {
    setSearch(param);
    setPage(0);
  }

  const onRowsPerPageChange = (param) => {
    setRowsPerPage(param);
  }

  const onPageChange = (param) => {
    setPage(param);
  }

  const onExportClick = () => {
    exportDevicesAction();
  }

  const renderImportButton = () => {
    return (
      <>
        <CustomButton label={t("common.import") + ' .csv'} onClick={() => fileInput.current.click()} style={{marginRight: '10px'}}/>
        <input  type="file" 
                accept=".csv" 
                ref={fileInput} 
                onChange={onImportFileUpload} 
                style={{display: "none"}}/>
      </>
    );
  }

  const renderTitle = () => {
    return (
      <>
        <div className="screenTitleContainer spaceBetween">
          <div className="screenTitleText">
            {t("sideMenu.devices")}
          </div>
          <div className="row">
            <CustomButton label={t("common.export")} onClick={onExportClick} style={{marginRight: '10px'}}/>
            {renderImportButton()}
            <CustomButton label={t("common.add")} onClick={() => navigate("/devices/add")}/>
          </div>
        </div>
        <div className="statusButtons">
          {Object.keys(DeviceStatus).map(status => (
            <div  className={"statusButton" + (status === selectedStatus ? " statusButtonActive" : "")}
                  key={status}
                  onClick={() => setSelectedStatus(status)}
            >
              {t("common.status." + status)}
            </div>
          ))}
        </div>
      </>
    );
  }

  const renderAssignee = (rowData) => {
    const avatar = rowData.assignee && rowData.assignee.avatar ? rowData.assignee.avatar : "/blankAvatar.png";
    return (
      <div className = "userInformationLabel">
        <img src={avatar} alt="avatar" className="userInformationAvatar"/>
        <div className = "userInformationLabelText">
          {rowData.assignee.username}
        </div>
      </div>
    );
  }

  return (
    <div className="innerScreenContainer deviceManagementTable">
      <MaterialTable
        icons={tableIcons}
        tableRef={tableRef}
        columns={[
          { 
            title: t("tableCommons.deviceNameLabel"), 
            field: 'deviceName',
            customSort: (a, b) => a.deviceName.localeCompare(b.deviceName, user.language) 
          },
          { 
            title: t("tableCommons.deviceNumberLabel"), 
            field: 'deviceNumber',
            customSort: (a, b) => a.deviceNumber.localeCompare(b.deviceNumber, user.language)
          },
          { 
            title: t("tableCommons.assignedToLabel"), 
            render: renderAssignee,
            customFilterAndSearch: (term, rowData) => rowData.assignee.username.toLowerCase().indexOf(term.toLowerCase()) !== -1,
            sorting: false
          },
          { 
            title: t("tableCommons.powerTypeLabel"), 
            field: 'powerType',
            customSort: (a, b) => a.rawPowerType.localeCompare(b.rawPowerType, user.language)
          },
          { 
            title: t("tableCommons.deviceConditionLabel"), 
            field: 'deviceCondition',
            customSort: (a, b) => a.rawDeviceCondition.localeCompare(b.rawDeviceCondition, user.language)
          },
        ]}
        data={mappedDevices}
        title={renderTitle()}
        onRowClick={onRowClick}
        options={{
          paging: true,
          pageSize: rowsPerPage,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100, 999],
          rowStyle: (rowData) => {
            return rowData.rawDeviceCondition === DeviceCondition.BROKEN ? {backgroundColor: "#FFCCCB"} : {};
          },
          searchText: search
        }}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        onOrderChange={onOrderChange}
        onSearchChange={onSearchChange}
        page={page}
        totalCount={(devices && devices.totalElements) ? devices.totalElements : 0}
        localization={{
          body: {
            emptyDataSourceMessage: t("tableCommons.noRecords")
          },
          toolbar: {
            searchPlaceholder: t("common.search")
          },
          pagination: {
            labelRowsSelect: t("tableCommons.rows")
          }
        }}
      />
    </div>
  );
}

export default DeviceManagement;
