import React, { useEffect, useState } from 'react';
import './ConstructionManagement.css';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../utils/tableUtils';
import moment from 'moment';
import CustomButton from '../../components/CustomButton/CustomButton';
import ConstructionStatus from '../../constants/ConstructionStatus';
import { fetchConstructionsAction } from '../../redux/actions/constructionActions';
import { useNavigate } from "react-router-dom";

function ConstructionManagement() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(ConstructionStatus.ACTIVE);
  const constructions = useSelector((state) => state.construction.constructions);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (selectedStatus)
      fetchConstructionsAction(selectedStatus);
  }, [selectedStatus]);

  const onRowClick = (event, rowData) => {
    navigate("/constructions/edit/" + rowData.id);
  }

  const mappedConstructions = !constructions ? [] : constructions.map(construction => ({
    ...construction,
    status: t("common.status." + construction.status),
    creationDate: moment(construction.creationDate).format("DD/MM/YYYY HH:mm")
  }))

  const renderTitle = () => {
    return (
      <>
        <div className="screenTitleContainer spaceBetween">
          <div className="screenTitleText">
            {t("sideMenu.constructions")}
          </div>
          <CustomButton label={t("common.add")} onClick={() => navigate("/constructions/add")}/>
        </div>
        <div className="statusButtons">
          {Object.keys(ConstructionStatus).map(status => (
            <div  className={"statusButton" + (status === selectedStatus ? " statusButtonActive" : "")}
                  key={status}
                  onClick={() => setSelectedStatus(status)}
            >
              {t("common.status." + status)}
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="innerScreenContainer">
      <MaterialTable
        icons={tableIcons}
        columns={[
          { 
            title: t("tableCommons.constructionNameLabel"), 
            field: 'constructionName',
            customSort: (a, b) => a.constructionName.localeCompare(b.constructionName, user.language)
          },
          { title: t("tableCommons.creationDateLabel"), field: 'creationDate' }
        ]}
        options={{
          paging:true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100],
        }}
        data={mappedConstructions}
        title={renderTitle()}
        onRowClick={onRowClick}
        localization={{
          body: {
              emptyDataSourceMessage: t("tableCommons.noRecords")
          },
          toolbar: {
            searchPlaceholder: t("common.search")
          },
          pagination: {
            labelRowsSelect: t("tableCommons.rows")
          }
        }}
      />
    </div>
  );
}

export default ConstructionManagement;
