import { createSlice } from '@reduxjs/toolkit'

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    isLoading: false,
    workDays: []
  },
  reducers: {
    fetchCalendarStart: (state) => {
        state.isLoading = true
    },
    fetchCalendarWorkDaysSuccess: (state, action) => {
        state.workDays = action.payload;
        state.isLoading = false;
    },
    fetchCalendarFailed: (state) => {
      state.isLoading = false
    }
  },
})

export const { 
  fetchCalendarStart, 
  fetchCalendarWorkDaysSuccess, 
  fetchCalendarFailed
} = calendarSlice.actions

export default calendarSlice.reducer