import React, { useEffect } from 'react';
import './DeviceEdit.css';
import {useTranslation} from "react-i18next";
import { fetchDeviceHistoryAction } from '../../../redux/actions/deviceActions';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../../utils/tableUtils';

function DeviceHistoryList({deviceId}) {

  const {t} = useTranslation();
  const deviceHistory = useSelector((state) => state.device.deviceHistory);

  useEffect(() => {
    fetchDeviceHistoryAction(deviceId);
  }, []);
 
  const mappedHistories = !deviceHistory ? [] : deviceHistory.map(h => ({
    ...h,
    date: moment(h.date).format("DD/MM/YYYY HH:mm")
  }))

  const renderUserCell = (rowData) => {
    const avatar = rowData.user && rowData.user.avatar ? rowData.user.avatar : "/blankAvatar.png";
    return (
      <div className = "userInformationLabel">
        <img src={avatar} alt="avatar" className="userInformationAvatar"/>
        <div className = "userInformationLabelText">
          {rowData.user.username}
        </div>
      </div>
    );
  }

  return (
    <MaterialTable
      icons={tableIcons}
      columns={[
        { title: t("tableCommons.date"), field: 'date' },
        { title: t("tableCommons.user"), render: renderUserCell },
        { title: t("tableCommons.comment"), field: 'comment' }
      ]}
      data={mappedHistories}
      title={null}
      options={{
        paging: false,
        sorting: false,
        search: false
      }}
      localization={{
        body: {
            emptyDataSourceMessage: t("tableCommons.noRecords")
        },
      }}
    />
  );
}

export default DeviceHistoryList;
