import React, { useState, useEffect } from 'react';
import './FuelTankEdit.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import { clearContextFuelTankAction, editFuelTankAction, fetchContextFuelTankAction } from '../../../redux/actions/fuelTankActions';
import FuelTankStatus from '../../../constants/FuelTankStatus';
import FuelLogList from '../../FuelLogList/FuelLogList';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { MdKeyboardBackspace } from 'react-icons/md';
import { isNumberValid } from '../../../utils/validationUtils';

const Section = {
  EDIT: "EDIT",
  FUEL_LOG: "FUEL_LOG"
}

function FuelTankEdit() {

  const {t} = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const fuelTankId = params.fuelTankId;
  const contextFuelTank = useSelector((state) => state.fuelTank.contextFuelTank);
  const statusOptions = Object.keys(FuelTankStatus).map(key => ({value: key, label: t("common.status." + key)}));

  const [fuelTankName, setFuelTankName] = useState("");
  const [liters, setLiters] = useState("");
  const [status, setStatus] = useState(null);
  const [section, setSection] = useState(Section.EDIT);
  const [error, setError] = useState(null);

  useEffect(() => {
    return clearContextFuelTankAction;
  }, []);

  useEffect(() => {
    if (section === Section.EDIT && fuelTankId)
      fetchContextFuelTankAction(fuelTankId)
  }, [section]);

  useEffect(() => { 
    if (fuelTankId && !contextFuelTank)
      fetchContextFuelTankAction(fuelTankId);
    
    if (contextFuelTank) {
      setFuelTankName(contextFuelTank.fuelTankName);
      setLiters(contextFuelTank.liters);
      setStatus(statusOptions.find(s => s.value === FuelTankStatus[contextFuelTank.status]));  
    }

  }, [contextFuelTank, fuelTankId]);

  const onSubmit = () => {
    if (liters && !isNumberValid(liters)) {
      setError(t('error.litersFormat'));
    } else {
      editFuelTankAction({
        id: contextFuelTank.id, 
        fuelTankName,
        liters,
        status: status.value
      }, () => navigate("fuelTanks"));
    }
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer">
        <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
        { contextFuelTank &&
          <div className="nestedScreenTitle">
            {t("fuelTankManagement.editTitle") + contextFuelTank.fuelTankName}
          </div>
        }
      </div>
    )
  }

  const renderSectionButtons = () => {
    return (
      <div className="bottomStatusButtons" >
        {Object.keys(Section).map(s => (
            <div  className={"statusButton" + (s === section ? " statusButtonActive" : "")}
                  key={s}
                  onClick={() => setSection(Section[s])}
          >
            {t("userBottomSections." + s)}
          </div>
        ))}
      </div>
    );
  }

  const renderEditForm = () => {
    return (
      <div className="fuelTankEditForm">
        <TextInput  value={fuelTankName} 
                    onChange={setFuelTankName} 
                    label={t("tableCommons.fuelTankNameLabel")} 
                    className="halfWidth"
                    style={{marginTop: "20px"}}
                    maxLength={255} />
        <TextInput  value={liters} 
                    onChange={setLiters} 
                    label={t("tableCommons.litersLabel")} 
                    className="halfWidth"
                    maxLength={60}/>
        <CustomDropdown value={status} 
                        onChange={setStatus} 
                        options={statusOptions} 
                        label={t("tableCommons.statusLabel")} 
                        className="halfWidth"/>
        {error && 
          <div className="errorMessage">
            {error}
          </div>
        }
        <div className="formButtons halfWidth">
          <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!fuelTankName}/>
        </div>
      </div>
    );
  }

  const renderSection = () => {
    if (!contextFuelTank)
      return null;

    switch(section) {
      case Section.FUEL_LOG:
          return <FuelLogList fuelTank={contextFuelTank}/>
      case Section.EDIT:
      default:
        return renderEditForm();
    }
  }

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      {renderSectionButtons()}
      {renderSection()}
    </div>
  );
}

export default FuelTankEdit;
