import Holidays from 'date-holidays';

export const getYearOptions = () => {
  let currentYear = new Date().getFullYear();
  let years = [];

  for (let i = -10; i <= 10; i++) {
      years.push(currentYear + i);
  }
  return years.map(key => ({value: key.toString(), label: key.toString()}));
};

export const getMonthOptions = () => {
  let months = [];

  for (let i = 1; i <= 12; i++) {
    if (i.toString().length === 1) {
      months.push("0" + i);
    } else {
      months.push(i);
    }
  }
  return months.map(key => ({value: key.toString(), label: key.toString()}));
};

export const isHoliday = (dateToCheck) => {
  const hd = new Holidays("PL");
  const contextHolidays = hd.isHoliday(new Date(dateToCheck));
  return contextHolidays && contextHolidays.length > 0 && contextHolidays[0].type == "public";
};
