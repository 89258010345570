import React from 'react';
import './TextArea.css';

function TextArea({value, onChange, label, hasError, className, maxLength}) {

  const handleChange = (event) => {
    onChange(event.target.value);
  }

  return (
    <div className={"textAreaContainer" + (className ? (" " + className) : "")}>
      {label && 
        <div className={"textAreaLabel" + (hasError ? " labelError" : "")}>
          {label}
        </div>
      }
      <textarea className="textArea" rows="5" value={value} onChange={handleChange} maxLength={maxLength} />
    </div>
  );
}

export default TextArea;
