import React, {useState} from 'react';
import '../Reports.css';
import {useTranslation} from "react-i18next";
import UserSelect from '../../../components/UserSelect/UserSelect';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { generateUserWorkLogReportAction } from '../../../redux/actions/reportActions';
import moment from 'moment';

function UserWorkLogReport() {
  const {t} = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onSubmit = () => {
    generateUserWorkLogReportAction({
      userId: selectedUserId,
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf()
    });
  }

  const renderTitle = () => {
    return (
      <div className="reportTitleContainer">
        <div className="reportTitleText">
          {t("reports.userWorkLogReportTitle")}
        </div>    
      </div>
    )
  }

  return (
    <div className="reportContainer">
      {renderTitle()}
      <div className="reportDescription">
        {t("reports.userWorkLogReportDescription")}
      </div>
      <div className="reportForm">
        <div className="reportFormRow">
          <UserSelect label={t("common.user")} 
                      value={selectedUserId} 
                      onChange={setSelectedUserId} 
                      className='reportInput'/>
          <CustomDatePicker value={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            label={t("reports.startDate")} 
                            className='reportInput'/>
          <CustomDatePicker value={endDate} 
                            onChange={(date) => setEndDate(date)} 
                            label={t("reports.endDate")} 
                            className='reportInput'/>
        </div>
        <CustomButton label={t("common.generate")} onClick={onSubmit} isDisabled={!selectedUserId || !startDate || !endDate}/>
      </div>
    </div>
  );
}

export default UserWorkLogReport;
