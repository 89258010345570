import React, {useEffect, useState} from 'react';
import '../Reports.css';
import {useTranslation} from "react-i18next";
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { generateFuelLogReportAction } from '../../../redux/actions/reportActions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import FuelTankStatus from '../../../constants/FuelTankStatus';
import { fetchFuelTanksAction } from '../../../redux/actions/fuelTankActions';

function FuelLogReport() {
  const {t} = useTranslation();
  const fuelTanks = useSelector((state) => state.fuelTank.fuelTanks);
  const fuelTankOptions = (fuelTanks && fuelTanks.length > 0) ? 
    fuelTanks.map(f => ({
      value: f.id, 
      label: f.fuelTankName
    })) : 
    [];
  const [selectedFuelTank, setSelectedFuelTank] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    fetchFuelTanksAction(FuelTankStatus.ACTIVE);
  }, []);

  const onSubmit = () => {
    generateFuelLogReportAction({
      fuelTankId: selectedFuelTank.value,
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf()
    });
  }

  const renderTitle = () => {
    return (
      <div className="reportTitleContainer">
        <div className="reportTitleText">
          {t("reports.fuelLogReportTitle")}
        </div>    
      </div>
    )
  }

  return (
    <div className="reportContainer">
      {renderTitle()}
      <div className="reportDescription">
        {t("reports.fuelLogReportDescription")}
      </div>
      <div className="reportForm">
        <div className="reportFormRow">
          <CustomDropdown value={selectedFuelTank} 
                          onChange={setSelectedFuelTank} 
                          options={fuelTankOptions} 
                          label={t("reports.fuelTank")} 
                          className="reportInput reportDropdown"/>
          <CustomDatePicker value={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            label={t("reports.startDate")} 
                            className='reportInput'/>
          <CustomDatePicker value={endDate} 
                            onChange={(date) => setEndDate(date)} 
                            label={t("reports.endDate")} 
                            className='reportInput'/>
        </div>
        <CustomButton label={t("common.generate")} onClick={onSubmit} isDisabled={!selectedFuelTank || !startDate || !endDate}/>
      </div>
    </div>
  );
}

export default FuelLogReport;
