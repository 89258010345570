// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customUserRoleContainer {
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}

.customUserRoleText {
  font-size: 12px;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomUserRole/CustomUserRole.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".customUserRoleContainer {\n  padding-right: 6px;\n  padding-left: 6px;\n  padding-top: 3px;\n  padding-bottom: 3px;\n  border-radius: 5px;\n  justify-content: center;\n  align-items: center;\n  width: fit-content;\n}\n\n.customUserRoleText {\n  font-size: 12px;\n  color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
