import { createSlice } from '@reduxjs/toolkit'

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    isLoading: false,
    devices: [],
    deviceDocuments: [],
    deviceHistory: [],
    contextDevice: null
  },
  reducers: {
    fetchDevicesStart: (state) => {
        state.isLoading = true
    },
    fetchDevicesSuccess: (state, action) => {
        state.devices = action.payload;
        state.isLoading = false;
    },
    fetchDeviceDocumentsSuccess: (state, action) => {
      state.deviceDocuments = action.payload;
      state.isLoading = false;
    },
    fetchDeviceHistorySuccess: (state, action) => {
      state.deviceHistory = action.payload;
      state.isLoading = false;
    },
    fetchContextDeviceSuccess: (state, action) => {
      state.contextDevice = action.payload;
      state.isLoading = false;
    },
    fetchDevicesFailed: (state) => {
        state.isLoading = false
    },
    fetchDevicesVoidSuccess: (state) => {
      state.isLoading = false
  },
  },
})

export const { 
  fetchDevicesStart, 
  fetchDevicesSuccess, 
  fetchContextDeviceSuccess,
  fetchDeviceDocumentsSuccess,
  fetchDevicesFailed,
  fetchDevicesVoidSuccess
} = deviceSlice.actions

export default deviceSlice.reducer