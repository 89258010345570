import React, {useEffect} from 'react';
import {
  BrowserRouter,
} from "react-router-dom";
import { useSelector } from 'react-redux';
import AuthorizedUserRouter from './AuthorizedUserRouter';
import AuthorizedSuperAdminRouter from './AuthorizedSuperAdminRouter';
import LoginRouter from './LoginRouter';
import UserRole from '../constants/UserRole';
import { refreshAuthorizationAction } from '../redux/actions/authActions';

function MainRouter() {

  const user = useSelector((state) => state.auth.user);

  useEffect(() => { 
    refreshAuthorizationAction();
  }, []);

  const renderContent = () => {
    if (user && user.role === UserRole.SUPER_ADMIN)
      return <AuthorizedSuperAdminRouter />
    else if (user && (user.role === UserRole.TENANT_ADMIN || user.role === UserRole.TENANT_DIRECTOR))
      return <AuthorizedUserRouter />
    else
      return <LoginRouter />
  }

  return (
    <BrowserRouter>
      {renderContent()}
    </BrowserRouter>
  );
}

export default MainRouter;
