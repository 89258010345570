import axiosService from './axiosService';

export const getAllUsers = (tenantId, userStatus) => {
  const statusString = userStatus ? `&status=${userStatus}` : '';
  return axiosService.get(`/user?tenantId=${tenantId}${statusString}`);
}

export const getUserById = (userId) => {
  return axiosService.get(`/user/id/` + userId);
}

export const addUser = (body) => {
  return axiosService.post("/user", body);
}

export const editUser = (body) => {
  return axiosService.put("/user", body);
}

export const editUserPassword = (body) => {
  return axiosService.put("/user/password", body);
}

export const addUserDocument = (body) => {
  return axiosService.post("/userDocument", body);
}

export const getUserDocuments = (userId) => {
  return axiosService.get("/userDocument?userId=" + userId);
}

export const deleteUserDocument = (userDocumentId) => {
  return axiosService.delete("/userDocument/" + userDocumentId);
}

export const getUserDevices = (userId) => {
  return axiosService.get("/userDevice/user/" + userId + "?deviceStatus=ACTIVE");
}