import React from 'react';
import './LanguageChange.css';
import { useSelector } from 'react-redux';
import Language from '../../constants/Language';
import { changeLanguageAction } from '../../redux/actions/authActions';
import { useTranslation } from 'react-i18next';

function LanguageChange() {
  const {t} = useTranslation();
  const user = useSelector((state) => state.auth.user);

  const languageOptions = {
    [Language.PL]: "plFlag.png",
    [Language.EN]: "ukFlag.png"
  }

  const onChange = (language) => {
    changeLanguageAction(language);
  }

  return (
    <div className="languageChangeContainer">
      {Object.keys(languageOptions).map(language => 
        <div  key={language}
              className={"languageChangeItem" + (user.language === language ? " languageChangeItemActive" : "")}
              onClick={() => onChange(language)}
        >
          <img src={languageOptions[language]} className="languageFlag" alt={language} />
        </div>
      )}
    </div>
  );
}

export default LanguageChange;
