import React, { useState } from 'react';
import './FuelTankAdd.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { addFuelTankAction } from '../../../redux/actions/fuelTankActions';
import { useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from 'react-icons/md';
import { isNumberValid } from '../../../utils/validationUtils';

function FuelTankAdd({onResetStatus}) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [fuelTankName, setFuelTankName] = useState("");
  const [liters, setLiters] = useState("");
  const [error, setError] = useState(null);

  const onSubmit = () => {
    if (liters && !isNumberValid(liters)) {
      setError(t('error.litersFormat'));
    } else {
      addFuelTankAction({
        fuelTankName, 
        liters
      }, () => navigate("/fuelTanks"));
          
      if (onResetStatus)
        onResetStatus();
    }
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer">
        <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
        <div className="nestedScreenTitle">
          {t("fuelTankManagement.addTitle")}
        </div>
      </div>
    )
  }

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      <TextInput  value={fuelTankName} 
                  onChange={setFuelTankName} 
                  label={t("tableCommons.fuelTankNameLabel")} 
                  className="halfWidth"
                  maxLength={255} />
      <TextInput  value={liters} 
                  onChange={setLiters} 
                  label={t("tableCommons.litersLabel")} 
                  className="halfWidth"
                  maxLength={60}/>
      {error && 
        <div className="errorMessage">
          {error}
        </div>
      }
      <div className="formButtons halfWidth">
        <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!fuelTankName}/>
      </div>
    </div>
  );
}

export default FuelTankAdd;
