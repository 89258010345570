// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deviceAddUserField {
  margin-left: 20px;
  margin-right: 20px;
  width: 50% !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/DeviceManagement/DeviceAdd/DeviceAdd.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[".deviceAddUserField {\n  margin-left: 20px;\n  margin-right: 20px;\n  width: 50% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
