import axiosService from './axiosService';

export const getAllDevices = (status, page, rowsPerPage, sortBy, sortDirection, search) => {
  let params = "";
  if (page || page === 0) {
    params = params + "&page=" + page;
  }
  if (rowsPerPage) {
    params = params + "&pageSize=" + rowsPerPage;
  }
  if (sortBy) {
    params = params + "&sortBy=" + sortBy;
  }
  if (sortDirection && sortDirection.toLowerCase().indexOf("desc") >= 0) {
    params = params + "&sortDescending=true";
  }
  if (search) {
    params = params + "&search=" + search;
  }

  return axiosService.get("/device/page?status=" + status + params);
}

export const getDeviceById = (deviceId) => {
  return axiosService.get("/device/id/" + deviceId);
}

export const addDevice = (body) => {
  return axiosService.post("/device", body);
}

export const editDevice = (body) => {
  return axiosService.put("/device", body);
}

export const assignDevice = (body) => {
  return axiosService.post("/userDevice", body);
}

export const addDeviceDocument = (body) => {
  return axiosService.post("/deviceDocument", body);
}

export const getDeviceDocuments = (deviceId) => {
  return axiosService.get("/deviceDocument?deviceId=" + deviceId);
}

export const getDeviceHistory = (deviceId) => {
  return axiosService.get("/device/history?deviceId=" + deviceId);
}

export const deleteDeviceDocument = (deviceDocumentId) => {
  return axiosService.delete("/deviceDocument/" + deviceDocumentId);
}

export const exportDevices = () => {
  return axiosService.get("/device/export");
}

export const importDevices = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return axiosService.post("/device/import", formData);
}
