import axiosService from './axiosService';
import {blobAxiosService} from './axiosService';

export const generateUserWorkLogReport = (data) => {
  return axiosService.post("/report/userWorkLog", data);
}

export const generateConstructionWorkLogReport = (data) => {
  return axiosService.post("/report/constructionWorkLog", data);
}

export const generateFuelLogReport = (data) => {
  return axiosService.post("/report/fuelLog", data);
}

export const generateUserWorkMonthReport = (data) => {
  return blobAxiosService.post("/report/userWorkMonth", data);
}
