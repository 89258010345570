import { Store } from 'react-notifications-component';

export const NotificationType = {
    SUCCESS: "success",
    DANGER: "danger",
    WARNING: "warning"
}

export const showNotification = (type, message) => {
    Store.addNotification({
        message: message,
        type: type,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            pauseOnHover: true
        }
    });
}
