import axiosService from './axiosService';

export const getAllTenants = (status) => {
  return axiosService.get("/tenant?status=" + status);
}

export const getTenantById = (tenantId) => {
  return axiosService.get("/tenant/" + tenantId);
}

export const addTenant = (body) => {
  return axiosService.post("/tenant", body);
}

export const editTenant = (body) => {
  return axiosService.put("/tenant", body);
}