// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editWorkLogForm {
    margin-bottoM: 50px;
}

.autoClosedInfo {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
    color: #e51b22;
    font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/screens/WorkLogList/WorkLogList.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".editWorkLogForm {\n    margin-bottoM: 50px;\n}\n\n.autoClosedInfo {\n    margin-left: 20px;\n    margin-right: 20px;\n    font-size: 16px;\n    color: #e51b22;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
