import React from 'react';
import './Reports.css';
import UserWorkLogReport from './forms/UserWorkLogReport';
import { useTranslation } from 'react-i18next';
import ConstructionWorkLogReport from './forms/ConstructionWorkLogReport';
import FuelLogReport from './forms/FuelLogReport';
import MonthlyUserWorkLogReport from './forms/UserWorkMonthReport';

function Reports() {
  const {t} = useTranslation();

  return (
    <div className="innerScreenContainer">
      <div className="screenTitleContainer">
        <div className="screenTitleText">
          {t("sideMenu.reports")}
        </div>
      </div>
      <MonthlyUserWorkLogReport />
      <UserWorkLogReport />
      <ConstructionWorkLogReport />
      <FuelLogReport />
    </div>
  );
}

export default Reports;
