import { createSlice } from '@reduxjs/toolkit'

export const workLogSlice = createSlice({
  name: 'workLog',
  initialState: {
    isLoading: false,
    userWorkLogs: []
  },
  reducers: {
    fetchWorkLogsStart: (state) => {
        state.isLoading = true
    },
    fetchUserWorkLogsSuccess: (state, action) => {
        state.userWorkLogs = action.payload;
        state.isLoading = false;
    },
    fetchWorkLogsFailed: (state) => {
        state.isLoading = false
    },
  },
})

export const { 
  fetchWorkLogsStart, 
  fetchUserWorkLogsSuccess, 
  fetchWorkLogsFailed
} = workLogSlice.actions

export default workLogSlice.reducer