import { createSlice } from '@reduxjs/toolkit'

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    isLoading: false,
  },
  reducers: {
    fetchReportsStart: (state) => {
        state.isLoading = true
    },
    fetchReportsVoidSuccess: (state) => {
        state.isLoading = false;
    },
    fetchReportsFailed: (state) => {
        state.isLoading = false
    },
  },
})

export const { 
  fetchReportsStart, 
  fetchReportsVoidSuccess, 
  fetchReportsFailed
} = reportSlice.actions

export default reportSlice.reducer