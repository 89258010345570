import React from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import WithTopBarAndMenuHoc from '../hoc/WithTopBarAndMenuHoc/WithTopBarAndMenuHoc';
import PageNotFound from '../screens/PageNotFound/PageNotFound';
import TenantAdd from '../screens/TenantManagement/TenantAdd/TenantAdd';
import TenantEdit from '../screens/TenantManagement/TenantEdit/TenantEdit';
import TenantManagement from '../screens/TenantManagement/TenantManagement';
import UserAdd from '../screens/UserManagement/UserAdd/UserAdd';
import UserEdit from '../screens/UserManagement/UserEdit/UserEdit';
import UserManagement from '../screens/UserManagement/UserManagement';

function AuthorizedSuperAdminRouter() {

  const WrappedTenantManagement = WithTopBarAndMenuHoc(TenantManagement);
  const WrappedAddTenantForm = WithTopBarAndMenuHoc(TenantAdd);
  const WrappedTenantDetails = WithTopBarAndMenuHoc(TenantEdit);
  const WrappedUserManagement = WithTopBarAndMenuHoc(UserManagement);
  const WrappedAddUserForm = WithTopBarAndMenuHoc(UserAdd);
  const WrappedUserDetails = WithTopBarAndMenuHoc(UserEdit);

  return (
    <Routes>
      <Route path="/tenants" element={<WrappedTenantManagement />} />
      <Route path="/tenants/add" element={<WrappedAddTenantForm />} />
      <Route path="/tenants/edit/:tenantId" element={<WrappedTenantDetails />} />
      <Route path="/users" element={<WrappedUserManagement />} />
      <Route path="/users/add/:tenantId" element={<WrappedAddUserForm />} />
      <Route path="/users/edit/:userId" element={<WrappedUserDetails />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AuthorizedSuperAdminRouter;
