import { initializeApp } from "firebase/app";
import { getStorage, uploadBytes, ref } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

export const initializeFirebaseApp = () => {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
    };

    initializeApp(firebaseConfig);
}

export const uploadFile = (fileName, fileContent) => {
    const ext = fileName.split('.').pop();
    const storage = getStorage();
    const storageRef = ref(storage, uuidv4() + "." + ext);
    return uploadBytes(storageRef, fileContent).then(r => r.ref._location.path)
        .catch(e => console.log("Error during the image upload to Firebase storage: " + e));
}
