const ErrorCode = {
    TENANT_ID_NOT_RECOGNIZED: "TENANT_ID_NOT_RECOGNIZED",
    TENANT_NAME_NOT_RECOGNIZED: "TENANT_NAME_NOT_RECOGNIZED",
    USER_ALREADY_EXISTS: "USER_ALREADY_EXISTS",
    INCORRECT_ROLE_NAME: "INCORRECT_ROLE_NAME",
    WRONG_PASSWORD_FORMAT: "WRONG_PASSWORD_FORMAT",
    USER_ID_NOT_FOUND: "USER_ID_NOT_FOUND",
    TENANT_NAME_ALREADY_EXISTS: "TENANT_NAME_ALREADY_EXISTS",
    TENANT_ID_NOT_FOUND: "TENANT_ID_NOT_FOUND",
    USER_DOES_NOT_EXIST: "USER_DOES_NOT_EXIST",
    UNAUTHORIZED: "UNAUTHORIZED",
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    CONSTRUCTION_NAME_ALREADY_EXISTS: "CONSTRUCTION_NAME_ALREADY_EXISTS",
    CONSTRUCTION_ID_NOT_FOUND: "CONSTRUCTION_ID_NOT_FOUND",
    USER_DISABLED: "USER_DISABLED",
    DEVICE_NUMBER_ALREADY_EXISTS: "DEVICE_NUMBER_ALREADY_EXISTS",
    DEVICE_ID_NOT_FOUND: "DEVICE_ID_NOT_FOUND",
    USER_DOCUMENT_ID_NOT_FOUND: "USER_DOCUMENT_ID_NOT_FOUND",
    DEVICE_DOCUMENT_ID_NOT_FOUND: "DEVICE_DOCUMENT_ID_NOT_FOUND",
    WORK_LOG_ID_NOT_FOUND: "WORK_LOG_ID_NOT_FOUND",
    FUEL_TANK_NAME_ALREADY_EXISTS: "FUEL_TANK_NAME_ALREADY_EXISTS",
    FUEL_TANK_ID_NOT_FOUND: "FUEL_TANK_ID_NOT_FOUND",
    NOT_ENOUGH_FUEL_IN_TANK: "NOT_ENOUGH_FUEL_IN_TANK",
    FUEL_LOG_ID_NOT_RECOGNIZED: "FUEL_LOG_ID_NOT_RECOGNIZED",
    DEVICE_NUMBER_NOT_FOUND: "DEVICE_NUMBER_NOT_FOUND",
    USER_DEVICE_NOT_FOUND: "USER_DEVICE_NOT_FOUND",
    START_DATE_AFTER_END_DATE: "START_DATE_AFTER_END_DATE",
    DAY_OFF_ALREADY_EXISTS: "DAY_OFF_ALREADY_EXISTS",
    WORK_LOG_CREATE_DATE_CONFLICT: "WORK_LOG_CREATE_DATE_CONFLICT",
    EXISTING_OPEN_WORK_LOGS: "EXISTING_OPEN_WORK_LOGS"
}

export default ErrorCode;