import store from '../store';
import { getCalendarDays} from '../../services/calendarService';
import { calendarSlice } from '../features/calendarSlice';

export async function fetchCalendarWorkDaysAction(userId, startDate, endDate, zoneOffset) {
    store.dispatch(calendarSlice.actions.fetchCalendarStart());
    return getCalendarDays(userId, startDate, endDate, zoneOffset).then((workDaysFetchResponse) => {
        store.dispatch(calendarSlice.actions.fetchCalendarWorkDaysSuccess(workDaysFetchResponse.data));
    }).catch((error) => {
        store.dispatch(calendarSlice.actions.fetchCalendarFailed());
    });
}

export async function clearWorkDaysAction() {
    store.dispatch(calendarSlice.actions.fetchCalendarStart());
    store.dispatch(calendarSlice.actions.fetchCalendarWorkDaysSuccess(null));
    store.dispatch(calendarSlice.actions.fetchCalendarFailed());
}
