import { createSlice } from '@reduxjs/toolkit'

export const constructionSlice = createSlice({
  name: 'construction',
  initialState: {
    isLoading: false,
    constructions: [],
    contextConstruction: null
  },
  reducers: {
    fetchConstructionsStart: (state) => {
        state.isLoading = true
    },
    fetchConstructionsSuccess: (state, action) => {
        state.constructions = action.payload;
        state.isLoading = false;
    },
    fetchContextConstructionSuccess: (state, action) => {
      state.contextConstruction = action.payload;
      state.isLoading = false;
    },
    fetchConstructionsFailed: (state) => {
        state.isLoading = false
    },
  },
})

export const { 
  fetchConstructionsStart, 
  fetchConstructionsSuccess,
  fetchContextConstructionSuccess, 
  fetchConstructionsFailed
} = constructionSlice.actions

export default constructionSlice.reducer