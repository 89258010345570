import React, {useState, useEffect} from 'react';
import './WorkLogList.css';
import {useTranslation} from "react-i18next";
import { editWorkLogAction } from '../../redux/actions/workLogActions';
import CustomPopup from '../../components/CustomPopup/CustomPopup';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';

function WorkLogEditPopup({contextUser, workLog, onClose}) {
  const {t} = useTranslation();
  const [construction, setConstruction] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const constructions = useSelector((state) => state.construction.constructions);
  const constructionOptions = (constructions && constructions.length > 0) ? 
    constructions.map(c => ({
      value: c.id, 
      label: c.constructionName
    })) : 
    [];

  useEffect(() => {
    if (workLog) {
      setStartDate(workLog.startDate);
      setEndDate(workLog.endDate);
      if (constructionOptions && constructionOptions.length > 0) {
        setConstruction(constructionOptions.find(c => c.value == workLog.construction.id));
      }
    }
  }, [workLog, constructions]);

  const onWorkLogEdit = () => {
    const body = {
      id: workLog.id,
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),
      constructionId: construction.value
    };
    editWorkLogAction(body, contextUser.id).then(onClose);
  }

  const renderEditWorkLogForm = () => {
    return (
      <div className="editWorkLogForm">
        <CustomDropdown value={construction} 
                        onChange={setConstruction} 
                        options={constructionOptions} 
                        label={t("reports.construction")} 
                        className="reportInput"/>
        <CustomDatePicker value={startDate} 
                          onChange={(date) => setStartDate(date)} 
                          label={t("reports.startDate")} 
                          className='reportInput  marginRight marginTop'/>
        <CustomDatePicker value={endDate} 
                          onChange={(date) => setEndDate(date)} 
                          label={t("reports.endDate")} 
                          className='reportInput  marginRight marginTop'/>
        {workLog && workLog.isAutoClosed &&
          <div className="autoClosedInfo">
            {t("workLogManagement.autoClosedAsterixInfo")}
          </div>
        }
      </div>
    );
  }

  if (!workLog)
    return null;

  return (
    <CustomPopup  label={t("workLogManagement.editWorkLogTitle")} 
                  onAccept={onWorkLogEdit} 
                  customBody={renderEditWorkLogForm()}
                  close={onClose}/>
  );
}

export default WorkLogEditPopup;
