import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../../utils/tableUtils';
import { fetchUserDaysOffAction, deleteUserDayOffAction } from '../../../redux/actions/userDayOffActions';
import { MdDeleteOutline, MdEditNote } from "react-icons/md";
import CustomPopup from '../../../components/CustomPopup/CustomPopup';

function UserDaysOffList({userId}) {

  const {t} = useTranslation();
  const [dayOffIdToDelete, setDayOffIdToDelete] = useState(null);
  const [deleteModalContent, setDeleteModalContent] = useState(null);

  const userDaysOff = useSelector((state) => state.userDayOff.userDaysOff);

  useEffect(() => {
    fetchUserDaysOffAction(userId);
  }, []);

  const onDayOffDelete = (dayOffId) => {
    deleteUserDayOffAction(dayOffId, userId).then(() => {
      setDayOffIdToDelete(null);
      setDeleteModalContent(null);
    });
  }

  const onRowClick = (event, rowData) => {
    setDeleteModalContent(rowData);
  }

  const renderDeleteDaysOffPopup = () => {
    if (!deleteModalContent)
      return null;

    return (
      <CustomPopup  label={deleteModalContent.type} 
                    onAccept={() => setDeleteModalContent(null)} 
                    close={() => setDeleteModalContent(null)}
                    customBody={renderInnerDaysOff()}
                    withoutAccept={true}/>
    )
  }

  const renderInnerDaysOff = () => {
    const onClick = (dt) => setDayOffIdToDelete(userDaysOff.find(udo => udo.date === dt).id);
    const mappedData = deleteModalContent.dates.map(d => ({
      ...d,
      id: "inner-" + d,
      date: moment(d).format("DD/MM/YYYY"),
      delete: <MdDeleteOutline className="tableIcon" onClick={() => onClick(d)} color={'#e51b22'}/>
    }));

    return (
      <MaterialTable
        style={{width: "50%", marginLeft: "25%"}}
        icons={tableIcons}
        columns={[
          { title: t("tableCommons.date"), field: 'date' },
          { title: t("tableCommons.deleteLabel"), field: 'delete', width: "100px" }
        ]}
        data={mappedData}
        title={null}
        options={{
          paging: false,
          sorting: false,
          search: false
        }}
      />
    );
  }

  const renderConfirmDeleteDayOffPopup = () => {
    if (!dayOffIdToDelete)
      return null;

    const contextDayOff = userDaysOff.find(udo => udo.id === dayOffIdToDelete);
    return (
      <CustomPopup  label={t("userManagement.deleteDayOffTitle") + (contextDayOff ? (": " + contextDayOff.date) : "")} 
                    onAccept={() => onDayOffDelete(dayOffIdToDelete)} 
                    close={() => setDayOffIdToDelete(null)}/>
    )
  }

  let composedDaysOff = [];
  if (userDaysOff) {
    userDaysOff.forEach((udo, index) => {
      const lastComposedElement = composedDaysOff[composedDaysOff.length - 1];
      const lastProcessedDate = lastComposedElement ? lastComposedElement.dates[lastComposedElement.dates.length - 1] : null;
      if (
          index === 0 
          || (lastComposedElement && lastComposedElement.type !== udo.type)
          || (lastProcessedDate && moment(lastProcessedDate).subtract(1, "days").format("DD/MM/YYYY") !== moment(udo.date).format("DD/MM/YYYY"))
      ) {
        composedDaysOff.push({
          dates: [udo.date],
          type: udo.type
        });
      } else {
        composedDaysOff[composedDaysOff.length - 1].dates.push(udo.date);
      }
    });
  }

  const mappedDaysOff = composedDaysOff.map(d => ({
    ...d,
    date: moment(d.dates[d.dates.length - 1]).format("DD/MM/YYYY") + (d.dates.length !== 1 ? (" - " + moment(d.dates[0]).format("DD/MM/YYYY")) : ""),
    type: t("common.userDayOff." + d.type)
  }))

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: t("tableCommons.date"), field: 'date' },
          { title: t("tableCommons.type"), field: 'type' }
        ]}
        data={mappedDaysOff}
        title={null}
        options={{
          paging: false,
          sorting: false,
          search: false
        }}
        onRowClick={onRowClick}
        localization={{
          body: {
              emptyDataSourceMessage: t("tableCommons.noRecords")
          },
        }}
      />
      {renderDeleteDaysOffPopup()}
      {renderConfirmDeleteDayOffPopup()}
    </>
  );
}

export default UserDaysOffList;
