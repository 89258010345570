// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languageChangeContainer {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;

}

.languageChangeItem {
  cursor: pointer;
  margin-left: 10px;
}

.languageLabel {
  font-size: 14px;
}

.languageFlag {
  width: 24px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageChange/LanguageChange.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;;AAEnB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".languageChangeContainer {\n  display: flex;\n  flex-direction: row;\n  margin-left: 10px;\n  margin-right: 10px;\n  padding-top: 10px;\n\n}\n\n.languageChangeItem {\n  cursor: pointer;\n  margin-left: 10px;\n}\n\n.languageLabel {\n  font-size: 14px;\n}\n\n.languageFlag {\n  width: 24px;\n  height: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
