import React, { useState, useEffect } from 'react';
import './ConstructionEdit.css';
import {useTranslation} from "react-i18next";
import TextInput from '../../../components/TextInput/TextInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import { clearContextConstructionAction, editConstructionAction, fetchContextConstructionAction } from '../../../redux/actions/constructionActions';
import ConstructionStatus from '../../../constants/ConstructionStatus';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { MdKeyboardBackspace } from 'react-icons/md';

function ConstructionEdit() {

  const {t} = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const constructionId = params.constructionId;
  const contextConstruction = useSelector((state) => state.construction.contextConstruction);

  const statusOptions = Object.keys(ConstructionStatus).map(key => ({value: key, label: t("common.status." + key)}));

  const [constructionName, setConstructionName] = useState("");
  const [status, setStatus] = useState(null);
  const [locationLatitude, setLocationLatitude] = useState("");
  const [locationLongitude, setLocationLongitude] = useState("");

  useEffect(() => {
    return clearContextConstructionAction;
  }, []);

  useEffect(() => { 
    if (constructionId && !contextConstruction)
      fetchContextConstructionAction(constructionId);
    
    if (contextConstruction) {
      setConstructionName(contextConstruction.constructionName);
      setStatus(statusOptions.find(s => s.value === ConstructionStatus[contextConstruction.status]));
      setLocationLatitude(contextConstruction.locationLatitude);
      setLocationLongitude(contextConstruction.locationLongitude);
    }

  }, [contextConstruction, constructionId]);

  const onSubmit = () => {
    editConstructionAction({
      id: contextConstruction.id, 
      constructionName,
      status: status.value,
      locationLatitude, 
      locationLongitude
    });
  }

  const renderTitle = () => {
    return (
      <div className="screenTitleContainer">
        <MdKeyboardBackspace className="screenTitleTextIcon" onClick={() => navigate(-1)}/>
        { contextConstruction &&
          <div className="nestedScreenTitle">
            {t("constructionManagement.editTitle") + contextConstruction.constructionName}
          </div>
        }
      </div>
    )
  }

  return (
    <div className="innerScreenContainer">
      {renderTitle()}
      <TextInput  value={constructionName} 
                  onChange={setConstructionName} 
                  label={t("tableCommons.constructionNameLabel")} 
                  maxLength={255} 
                  className="halfWidth"/>
      <CustomDropdown value={status} 
                      onChange={setStatus} 
                      options={statusOptions} 
                      label={t("tableCommons.statusLabel")} 
                      className="halfWidth"/>
      <TextInput  value={locationLatitude} 
                  onChange={setLocationLatitude} 
                  label={t("tableCommons.locationLatitudeLabel")} 
                  maxLength={60} 
                  className="halfWidth"/>
      <TextInput  value={locationLongitude} 
                  onChange={setLocationLongitude} 
                  label={t("tableCommons.locationLongitudeLabel")} 
                  maxLength={60} 
                  className="halfWidth" />
      <div className="formButtons halfWidth">
        <CustomButton label={t("common.save")} onClick={onSubmit} isDisabled={!constructionName}/>
      </div>
    </div>
  );
}

export default ConstructionEdit;
