import React, { useEffect, useState } from 'react';
import { fetchCalendarWorkDaysAction, clearWorkDaysAction } from '../../redux/actions/calendarActions';
import './Calendar.css';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../utils/tableUtils';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';
import moment from 'moment';
import UserSelect from '../../components/UserSelect/UserSelect';
import DayOfWeek from '../../constants/DayOfWeek';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import { getYearOptions, getMonthOptions, isHoliday } from '../../utils/dateUtils';
import CustomPopup from '../../components/CustomPopup/CustomPopup';

function Calendar() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentMonthString = currentMonth < 10 ? "0" + currentMonth.toString() : currentMonth.toString();

  const {t} = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [month, setMonth] = useState(getMonthOptions().find(m => m.value === currentMonthString));
  const [year, setYear] = useState(getYearOptions().find(yo => yo.value === currentYear.toString()));
  const [detailsModalContent, setDetailsModalContent] = useState(null);

  const workDays = useSelector((state) => state.calendar.workDays);

  useEffect(() => {
    return clearWorkDaysAction;
  }, []);

  useEffect(() => {
    if (selectedUserId && year && month)
      fetchCalendarWorkDaysAction(selectedUserId, year.value, month.value, moment().format('Z')).then(() => {
        
      });
  }, [selectedUserId, month, year]);

  const getConstructionNames = (workedShifts) => {
    if (!workedShifts || workedShifts.length === 0)
      return "-";

    let names = [];
    workedShifts.forEach(ws => {
      if (!names.includes(ws.constructionName)) {
        names.push(ws.constructionName);
      }
    });
    return names.join(", ");
  }

  const renderDetailsPopup = () => {
    if (!detailsModalContent)
      return null;

    return (
      <CustomPopup  label={detailsModalContent.username + " - " + detailsModalContent.date} 
                    onAccept={() => setDetailsModalContent(null)} 
                    close={() => setDetailsModalContent(null)}
                    customBody={renderDetailsList()}
                    withoutAccept={true}/>
    )
  };

  const onRowClick = (event, rowData) => {
    if (rowData.workedShifts && rowData.workedShifts.length > 0)
      setDetailsModalContent(rowData);
  }

  const renderDetailsList = () => {
    const mappedData = detailsModalContent.workedShifts.map(s => ({
      ...s,
      id: "shift-" + s.startTime
    }));

    return (
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: t("reports.construction"), field: 'constructionName' },
          { title: t("tableCommons.startDateLabel"), field: 'startTime' },
          { title: t("tableCommons.endDateLabel"), field: 'endTime' },
          { title: t("tableCommons.hours"), field: 'hours' }
        ]}
        data={mappedData}
        title={null}
        options={{
          paging: false,
          sorting: false,
          search: false
        }}
      />
    );
  }

  const mappedWorkDays = !workDays ? [] : workDays.map(wd => ({
    ...wd,
    id: wd.date + wd.userName + "-work-day",
    date: moment(wd.date).format("DD/MM/YYYY") + " (" + t("common.dayOfWeek." + wd.dayOfWeek) + ")",
    dayOff: wd.userDayOff ? t("common.userDayOff." + wd.userDayOff.type) : "-",
    rawDayOfWeek: wd.dayOfWeek,
    presentOnConstructions: getConstructionNames(wd.workedShifts),
    rawDate: wd.date
  }));

  return (
    <div className="innerScreenContainer">
      <div className="reportForm">
        <div className="reportFormRow">
          <UserSelect label={t("common.user")} 
                      value={selectedUserId} 
                      onChange={setSelectedUserId} 
                      className="reportInput"/>
          <CustomDropdown value={month} 
                          onChange={setMonth} 
                          options={getMonthOptions()} 
                          label={t("tableCommons.monthLabel")} 
                          className="reportInput"/>
          <CustomDropdown value={year} 
                          onChange={setYear} 
                          options={getYearOptions()} 
                          label={t("tableCommons.yearLabel")} 
                          className="reportInput"/>
        </div>
      </div>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: t("tableCommons.date"), field: 'date' },
          { title: t("tableCommons.presentOnConstructions"), field: 'presentOnConstructions' },
          { title: t("tableCommons.dayOff"), field: 'dayOff' },
          { title: t("tableCommons.hours"), field: 'hours' }
        ]}
        data={mappedWorkDays}
        title={null}
        options={{
          paging: false,
          sorting: false,
          search: false,
          rowStyle: (rowData) => {
            if (rowData.rawDayOfWeek === DayOfWeek.SATURDAY) 
              return {backgroundColor: "#ffeaea"};
            else if (rowData.rawDayOfWeek === DayOfWeek.SUNDAY) 
              return {backgroundColor: "#ffe0df"};
            else if (isHoliday(rowData.rawDate))
              return {backgroundColor: "#ffe0df"};
            else
              return {};
          },
        }}
        onRowClick={onRowClick}
        localization={{
          body: {
              emptyDataSourceMessage: (!selectedUserId || !year || !month) ? t("tableCommons.fillParameters") : t("tableCommons.noRecords")
          },
        }}
      />
      {renderDetailsPopup()}
    </div>
  );
}

export default Calendar;
