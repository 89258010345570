import axiosService from './axiosService';

export const loginUser = (tenantName, username, password) => {
  const data = {
    tenantName,
    username,
    password
  }
  return axiosService.post("/auth/login", data);
}

export const loginSuperAdmin = (username, password) => {
  const data = {
    username,
    password
  }
  return axiosService.post("/auth/loginSuperAdmin", data);
}

export const getAuthorizedUser = () => {
  return axiosService.get("/user/authorized");
}