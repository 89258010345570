// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screenContainer {
  display: flex;
  flex-direction: column;
}

.screenWrapperContainer {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 200px);
}

`, "",{"version":3,"sources":["webpack://./src/hoc/WithTopBarAndMenuHoc/WithTopBarAndMenuHoc.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".screenContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.screenWrapperContainer {\n  display: flex;\n  flex-direction: row;\n  height: calc(100vh - 200px);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
