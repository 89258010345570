// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fetchingIndicator {
  background-color: #ffffff;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/components/FetchingIndicator/FetchingIndicator.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,aAAa;EACb,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf","sourcesContent":[".fetchingIndicator {\n  background-color: #ffffff;\n  position: fixed;\n  display: flex;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  z-index: 9999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
