import axiosService from './axiosService';

export const getPagedUserWorkLogs = (userId, page, rowsPerPage, sortBy, sortDirection) => {
  let params = "";
  if (page || page === 0) {
    params = params + "&page=" + page;
  }
  if (rowsPerPage) {
    params = params + "&pageSize=" + rowsPerPage;
  }
  if (sortBy) {
    params = params + "&sortBy=" + sortBy;
  }
  if (sortDirection && sortDirection.toLowerCase().indexOf("desc") >= 0) {
    params = params + "&sortDescending=true";
  }
  return axiosService.get("/workLog/page?userId=" + userId + params);
}

export const deleteWorkLog = (workLogId) => {
  return axiosService.delete("/workLog/" + workLogId);
}

export const addWorkLog = (body) => {
  return axiosService.post("/workLog", body);
}

export const editWorkLog = (body) => {
  return axiosService.put("/workLog", body);
}