export const isPasswordValid = password => {
  const regularExpression = new RegExp('^(?=.*[0-9])(?=.*[a-z]).{8,}$');
  return regularExpression.test(password);
};

export const isEmailValid = email => {
  const regularExpression = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,6}');
  return regularExpression.test(email);
};

export const isNumberValid = value => {
  const regularExpression = new RegExp('^[0-9]*$');
  return regularExpression.test(value);
};