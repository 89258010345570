import React from 'react';
import './TextInput.css';

function TextInput({value, onChange, label, type, maxLength, hasError, style, onEnter, className}) {

  const handleChange = (event) => {
    onChange(event.target.value);
  }

  const onKeyUp = (event) => {
    if(event.key === 'Enter' && onEnter){
      onEnter();
    }
  }

  return (
    <div className={"textInputContainer" + (className ? (" " + className) : "")} style={style ? style : {}}>
      {label && 
        <div className={"textInputLabel" + (hasError ? " labelError" : "")}>
          {label}
        </div>
      }
      <input  className="textInput" 
              type={type ? type : "text"} 
              value={value} 
              onChange={handleChange} 
              maxLength={maxLength}
              onKeyUp={onKeyUp}/>
    </div>
  );
}

export default TextInput;
