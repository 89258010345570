import React from 'react';
import './CustomDropdown.css';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function CustomDropdown({value, options, onChange, label, style, className}) {
  const {t} = useTranslation();
  const user = useSelector((state) => state.auth.user);

  const styles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#e51b22' : "#ffffff",
      "&:hover": {
        backgroundColor: "#f5cacb",
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 10000 })
  };

  const sortedOptions = (options && options.length > 0) ? options.sort((a, b) => a.label.localeCompare(b.label, user.language)) : [];

  return (
    <div key={label} className={"customDropdownContainer" + (className ? (" " + className) : "")} style={style ? style : {}}>
      <div className="customDropdownLabel">
        {label}
      </div>
      <Select isSearchable={true} 
        className="customDropdown"
        value={value} 
        onChange={onChange} 
        options={sortedOptions} 
        menuPortalTarget={document.body}  
        styles={styles}
        classNamePrefix="react-select"
        placeholder={t("common.select")}
      />
    </div>
  );
}

export default CustomDropdown;
