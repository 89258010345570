// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageNotFoundContainer {
  display: flex;
  flex: 1 1;
  align-items: center;
  flex-direction: column;
}

.pageNotFoundText {
  color: #e51b22;
  font-weight: 700;
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/PageNotFound/PageNotFound.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAO;EACP,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".pageNotFoundContainer {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  flex-direction: column;\n}\n\n.pageNotFoundText {\n  color: #e51b22;\n  font-weight: 700;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
