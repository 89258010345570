// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assignedUserContainer {
  margin-bottom: 20px;
  width: 48%;
}

.assignedUserInfoContainer {
  display: flex;
  flex-direction: row;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #f3f3f3;
  border: none;
  border-bottom: 1px solid #000000;
  padding: 8px;
  align-items: center;
}

.assignedUserAvatar {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.assignedUserText {
  color: hsl(0, 0%, 20%);
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignedUserLabel {
  color: #e51b22;
  margin-bottom: 5px;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/AssignedUser/AssignedUser.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,4BAA4B;EAC5B,yBAAyB;EACzB,YAAY;EACZ,gCAAgC;EAChC,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".assignedUserContainer {\n  margin-bottom: 20px;\n  width: 48%;\n}\n\n.assignedUserInfoContainer {\n  display: flex;\n  flex-direction: row;\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n  background-color: #f3f3f3;\n  border: none;\n  border-bottom: 1px solid #000000;\n  padding: 8px;\n  align-items: center;\n}\n\n.assignedUserAvatar {\n  border-radius: 30px;\n  width: 30px;\n  height: 30px;\n  margin-right: 10px;\n}\n\n.assignedUserText {\n  color: hsl(0, 0%, 20%);\n  font-size: 16px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.assignedUserLabel {\n  color: #e51b22;\n  margin-bottom: 5px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
