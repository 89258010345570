import React, {useEffect, useState} from 'react';
import './PageNotFound.css';
import {useTranslation} from "react-i18next";
import CustomButton from '../../components/CustomButton/CustomButton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import UserRole from '../../constants/UserRole';

function PageNotFound() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [delaying, setDelaying] = useState(true);

  useEffect(() => { 
    let timeout = setTimeout(() => setDelaying(false), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const isAuthLoading = useSelector((state) => state.auth.isLoading);
  const isUserLoading = useSelector((state) => state.user.isLoading);
  const isDeviceLoading = useSelector((state) => state.device.isLoading);
  const isConstructionLoading = useSelector((state) => state.construction.isLoading);
  const isFuelLogLoading = useSelector((state) => state.fuelLog.isLoading);
  const isFuelTankLoading = useSelector((state) => state.fuelTank.isLoading);
  const isReportLoading = useSelector((state) => state.report.isLoading);
  const isTenantLoading = useSelector((state) => state.tenant.isLoading);
  const isWorkLogLoading = useSelector((state) => state.workLog.isLoading);
  const isFetching = 
    isAuthLoading || 
    isUserLoading ||
    isDeviceLoading ||
    isConstructionLoading ||
    isFuelLogLoading ||
    isFuelTankLoading ||
    isReportLoading ||
    isTenantLoading ||
    isWorkLogLoading;

  if (delaying || isFetching)
    return null;

  const onRedirect = () => {
    if (!user)
      return () => navigate("/");

    switch (user.role) {
      case UserRole.TENANT_DIRECTOR:
      case UserRole.TENANT_ADMIN:
        return () => navigate("/users");
      case UserRole.SUPER_ADMIN:
        return () => navigate("/tenants");
      default:
        return () => navigate("/");
    }
  }

  return (
    <div className="pageNotFoundContainer">
      <img src={"logoBig.png"} className="loginScreenLogo" alt="logo" />
      <div className="pageNotFoundText">
        {t("error.pageNotFound")}
      </div>
      <CustomButton label={t("common.redirectToApp")} onClick={onRedirect()} style={{marginTop: "20px"}}/>
    </div>
  );
}

export default PageNotFound;
