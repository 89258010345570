// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userEditForm {
  display: flex;
  flex-direction: column;
  border: 1px solid #c0c0c0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-width: 0;
  margin-left: 16px;
  margin-right: 8px;
  background-color: #ffffff;
  box-shadow: 0px 6px 10px 0px rgba(163, 164, 167, 1);
}

.qrCodeSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #c0c0c0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-width: 0;
  margin-left: 16px;
  margin-right: 8px;
  background-color: #ffffff;
  box-shadow: 0px 6px 10px 0px rgba(163, 164, 167, 1);
}

.qrText {
  margin-bottom: 20px;
  color: #e51b22;
}

.innerDaysOffTable {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/screens/UserManagement/UserEdit/UserEdit.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,+BAA+B;EAC/B,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;EAGzB,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,yBAAyB;EACzB,+BAA+B;EAC/B,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;EAGzB,mDAAmD;AACrD;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".userEditForm {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #c0c0c0;\n  border-bottom-right-radius: 5px;\n  border-bottom-left-radius: 5px;\n  border-top-width: 0;\n  margin-left: 16px;\n  margin-right: 8px;\n  background-color: #ffffff;\n  -webkit-box-shadow: 0px 6px 10px 0px rgba(163, 164, 167, 1);\n  -moz-box-shadow: 0px 6px 10px 0px rgba(163, 164, 167, 1);\n  box-shadow: 0px 6px 10px 0px rgba(163, 164, 167, 1);\n}\n\n.qrCodeSectionContainer {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  border: 1px solid #c0c0c0;\n  border-bottom-right-radius: 5px;\n  border-bottom-left-radius: 5px;\n  border-top-width: 0;\n  margin-left: 16px;\n  margin-right: 8px;\n  background-color: #ffffff;\n  -webkit-box-shadow: 0px 6px 10px 0px rgba(163, 164, 167, 1);\n  -moz-box-shadow: 0px 6px 10px 0px rgba(163, 164, 167, 1);\n  box-shadow: 0px 6px 10px 0px rgba(163, 164, 167, 1);\n}\n\n.qrText {\n  margin-bottom: 20px;\n  color: #e51b22;\n}\n\n.innerDaysOffTable {\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
