// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportContainer {
  margin: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 2px 2px 9px 0px rgba(126, 126, 126, 1);
}

.reportDescription {
  font-size: 18px;
  margin: 20px;
  color: #000000;
}

.reportForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
  justify-content: space-between;
}

.reportFormRow {
  display: flex;
  flex-direction: row;
}

.reportInput {
  min-width: 300px;
}

.marginRight {
  margin-right: 20px;
}

.marginTop {
  margin-top: 20px;
}

.reportTitleContainer {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #e1e2e5;
  box-shadow: 2px 2px 9px 0px rgba(126, 126, 126, 1);
}

.reportTitleText {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  color: #e51b22;
  padding: 20px;
  border-bottom: 4px solid #e51b22;
}

.reportDropdown {
  margin-left: 0;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/screens/Reports/Reports.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EAGzB,kDAAkD;AACpD;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,2BAA2B;EAC3B,yBAAyB;EAGzB,kDAAkD;AACpD;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".reportContainer {\n  margin: 20px;\n  border-radius: 5px;\n  background-color: #ffffff;\n  -webkit-box-shadow: 2px 2px 9px 0px rgba(126, 126, 126, 1);\n  -moz-box-shadow: 2px 2px 9px 0px rgba(126, 126, 126, 1);\n  box-shadow: 2px 2px 9px 0px rgba(126, 126, 126, 1);\n}\n\n.reportDescription {\n  font-size: 18px;\n  margin: 20px;\n  color: #000000;\n}\n\n.reportForm {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin: 20px;\n  justify-content: space-between;\n}\n\n.reportFormRow {\n  display: flex;\n  flex-direction: row;\n}\n\n.reportInput {\n  min-width: 300px;\n}\n\n.marginRight {\n  margin-right: 20px;\n}\n\n.marginTop {\n  margin-top: 20px;\n}\n\n.reportTitleContainer {\n  border-top-right-radius: 5px;\n  border-top-left-radius: 5px;\n  background-color: #e1e2e5;\n  -webkit-box-shadow: 2px 2px 9px 0px rgba(126, 126, 126, 1);\n  -moz-box-shadow: 2px 2px 9px 0px rgba(126, 126, 126, 1);\n  box-shadow: 2px 2px 9px 0px rgba(126, 126, 126, 1);\n}\n\n.reportTitleText {\n  width: fit-content;\n  font-size: 20px;\n  color: #e51b22;\n  padding: 20px;\n  border-bottom: 4px solid #e51b22;\n}\n\n.reportDropdown {\n  margin-left: 0;\n  margin-right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
