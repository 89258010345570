import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import tenantReducer from './features/tenantSlice';
import userReducer from './features/userSlice';
import deviceReducer from './features/deviceSlice';
import constructionReducer from './features/constructionSlice';
import fuelTankReducer from './features/fuelTankSlice';
import fuelLogReducer from './features/fuelLogSlice';
import workLogReducer from './features/workLogSlice';
import reportReducer from './features/reportSlice';
import userDayOffReducer from './features/userDayOffSlice';
import calendarReducer from './features/calendarSlice';

export default configureStore({
  reducer: {
      auth: authReducer,
      tenant: tenantReducer,
      user: userReducer,
      construction: constructionReducer,
      device: deviceReducer,
      fuelTank: fuelTankReducer,
      fuelLog: fuelLogReducer,
      workLog: workLogReducer,
      report: reportReducer,
      userDayOff: userDayOffReducer,
      calendar: calendarReducer
  },
})