import React, { useEffect, useState } from 'react';
import './FuelLogList.css';
import {useTranslation} from "react-i18next";
import { tableIcons } from '../../utils/tableUtils';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import { useSelector } from 'react-redux';
import { deleteFuelLogAction, fetchFuelLogsAction } from '../../redux/actions/fuelLogActions';
import { MdDeleteOutline } from "react-icons/md";
import CustomPopup from '../../components/CustomPopup/CustomPopup';

function FuelLogList({fuelTank}) {

  const {t} = useTranslation();

  const fuelLogs = useSelector((state) => state.fuelLog.fuelLogs);
  const [fuelLogIdToDelete, setFuelLogIdToDelete] = useState(null);

  useEffect(() => { 
    if (fuelTank)
      fetchFuelLogsAction(fuelTank.id);
  }, [fuelTank]);

  const onFuelLogDelete = (fuelLogId) => {
    deleteFuelLogAction(fuelLogId, fuelTank.id).then(() => {
      setFuelLogIdToDelete(null);
    });
  }

  const mappedFuelLogs = !fuelLogs ? [] : fuelLogs.map(fuelLog => ({
    ...fuelLog,
    creationDate: moment(fuelLog.creationDate).format("DD/MM/YYYY HH:mm"),
    delete: <MdDeleteOutline className="tableIcon" onClick={() => setFuelLogIdToDelete(fuelLog.id)} color={'#e51b22'}/>
  }))

  const renderUserName = (rowData) => {
    const avatar = (rowData.user && rowData.user.avatar) ? rowData.user.avatar : "/blankAvatar.png";
    return (
      <div className = "userInformationLabel">
        <img src={avatar} alt="avatar" className="userInformationAvatar"/>
        <div className = "userInformationLabelText">
          {rowData.user.username}
        </div>
      </div>
    );
  }

  const renderDeleteFuelLogPopup = () => {
    if (!fuelLogIdToDelete)
      return null;

    return (
      <CustomPopup  label={t("fuelTankManagement.deleteFuelLogTitle")} 
                    onAccept={() => onFuelLogDelete(fuelLogIdToDelete)} 
                    close={() => setFuelLogIdToDelete(null)}/>
    )
  }

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: t("common.username"), field: 'user.username', render: renderUserName },
          { title: t("tableCommons.litersLabel"), field: 'liters' },
          { title: t("tableCommons.dateLabel"), field: 'creationDate' },
          { title: t("tableCommons.deviceNumberLabel"), field: 'device.deviceNumber' },
          { title: t("tableCommons.deviceNameLabel"), field: 'device.deviceName' },
          { title: t("tableCommons.deviceMileage"), field: 'mileage' },
          { title: t("tableCommons.previousTankLiters"), field: 'previousTankLiters' },
          { title: t("tableCommons.nextTankLiters"), field: 'nextTankLiters' },
          { title: t("tableCommons.deleteLabel"), field: 'delete', width: "100px" }
        ]}
        data={mappedFuelLogs}
        title={null}
        options={{
          search: false,
          paging:true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100],
        }}
        localization={{
          body: {
              emptyDataSourceMessage: t("tableCommons.noRecords")
          },
          toolbar: {
            searchPlaceholder: t("common.search")
          },
          pagination: {
            labelRowsSelect: t("tableCommons.rows")
          }
        }}
      />
      {renderDeleteFuelLogPopup()}
    </>

  );
}

export default FuelLogList;
