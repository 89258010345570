import React from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import Login from '../screens/Login/Login';
import PageNotFound from '../screens/PageNotFound/PageNotFound';
import SuperAdminLogin from '../screens/SuperAdminLogin/SuperAdminLogin';

function LoginRouter() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path={"/" + process.env.REACT_APP_SUPER_ADMIN_LOGIN_SUFFIX} element={<SuperAdminLogin />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default LoginRouter;
