// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customDropdownContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.customDropdownLabel {
  color: #e51b22;
  margin-bottom: 5px;
  font-weight: 700;
}

.customDropdown {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #f3f3f3;
  border: none;
  border-bottom: 1px solid #000000;
  font-size: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.react-select__control {
  border: none !important;
  background-color: #f3f3f3 !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomDropdown/CustomDropdown.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,yBAAyB;EACzB,YAAY;EACZ,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,oCAAoC;EACpC,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".customDropdownContainer {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  margin-left: 20px;\n  margin-right: 20px;\n}\n\n.customDropdownLabel {\n  color: #e51b22;\n  margin-bottom: 5px;\n  font-weight: 700;\n}\n\n.customDropdown {\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n  background-color: #f3f3f3;\n  border: none;\n  border-bottom: 1px solid #000000;\n  font-size: 16px;\n  padding-top: 4px;\n  padding-bottom: 4px;\n}\n\n.react-select__control {\n  border: none !important;\n  background-color: #f3f3f3 !important;\n  padding: 0 !important;\n  box-shadow: none !important;\n}\n\n.react-select__indicator-separator {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
